import React, { useEffect, useState } from "react";
import Message from "src/components/message/Message";
import { GoalStatementList } from "../../components/_dashboard/goalStatement";
import GoalGallerySlider from "./SelfImageGallerySlider";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { GoalStatementListApi } from "src/DAL/GoalStatement/GoalStatementList";
import { s3baseUrl } from "src/config/config";
import SelfImageList from "src/components/_dashboard/selfImage/SelfImageList";
import SelfImageGallerySlider from "./SelfImageGallerySlider";
import SelfImageMessageSearch from "src/components/message/SelfImageMessageSearch";
import {
  SelfImageListApi,
  SelfImageSearchApi,
} from "src/DAL/SelfImage/SelfImageList";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SelfImageSearch() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [goalStatementQuestions, setGoalStatementQuestions] = useState([]);
  const [goalStatementGallery, setGoalStatementGallery] = useState([]);
  const [selfImageCompletedDate, SetSelfImageCompletedDate] = useState("");
  const [selfImageCompletedStatus, SetSelfImageCompletedStatus] =
    useState(false);
  const [replies, setreplies] = useState([]);

  const getGoalStatementList = async () => {
    // const result = await SelfImageSearchApi();
    const result = await SelfImageListApi();
    if (result.code === 200) {
      console.log(result, "resultresult");
      setGoalStatementQuestions(result.self_image_question);
      SetSelfImageCompletedDate(result.self_image_completed_date);
      SetSelfImageCompletedStatus(result.self_image_completed_status);
      let replyArray = [];
      let replyObject = {};
      result.reply.map((reply, index) => {
        replyObject = {
          comment: reply.message,
          createdAt: reply.createdAt,
          image: reply.image,
          audio_file: reply.audio_file,
        };
        replyArray.push(replyObject);
      });

      setreplies(replyArray);
      let galleryArray = [];
      let galleryObject = {};
      result.self_image_gallery.map((gallery, index) => {
        galleryObject = {
          original: s3baseUrl + gallery.thumbnail_1,
          thumbnail: s3baseUrl + gallery.thumbnail_2,
        };
        galleryArray.push(galleryObject);
      });
      setGoalStatementGallery(galleryArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGoalStatementList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <SelfImageList />
        </div>
      </div>
      <br></br>
      {goalStatementGallery.length > 0 && (
        <div className="row mb-md-5">
          <SelfImageGallerySlider data={goalStatementGallery} />
        </div>
      )}

      <div className="row mt-md-5">
        <div className="col-12">
          <SelfImageMessageSearch
            replies={replies}
            data={goalStatementQuestions}
            selfImageCompletedDate={selfImageCompletedDate}
            selfImageCompletedStatus={selfImageCompletedStatus}
            getGoalStatementList={getGoalStatementList}
          />
        </div>
      </div>
    </div>
  );
}
