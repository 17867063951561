import { invokeApi } from "../../bl_libs/invokeApi";

export const marketPlaceListing = async (loadMore, data) => {
  const requestObj = {
    // path: `api/post/admin`,
    path: loadMore,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const marketPlaceListingClient = async (data) => {
  const requestObj = {
    path: `api/post/client`,
    // path: loadMore,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const marketPlaceListingSpecificClient = async (data, page, limit) => {
  const requestObj = {
    path: `api/post/client?page=${page}&limit=${limit}`,
    // path: loadMore,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const marketPlaceAddApi = async (data) => {
  const requestObj = {
    path: `api/post/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const marketPlaceEditApi = async (data, id) => {
  const requestObj = {
    path: `api/post/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const marketPlaceResubmitApi = async (data, id) => {
  const requestObj = {
    path: `api/post/resubmit_post/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const marketPlaceDeleteApi = async (id) => {
  const requestObj = {
    path: `api/post/${id}`,

    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const marketPlaceDetailApi = async (id) => {
  const requestObj = {
    path: `api/post/detail/${id}`,

    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
