import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Form, FormikProvider } from "formik";
import closeFill from "@iconify/icons-eva/close-fill";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
//
import Scrollbar from "../../Scrollbar";
import { get_root_value } from "src/utils/domUtils";
import { htmlDecode } from "src/utils/convertHtml";
// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: "featured", label: "Featured" },
  { value: "newest", label: "Newest" },
  { value: "priceDesc", label: "Price: High-Low" },
  { value: "priceAsc", label: "Price: Low-High" },
];

// ----------------------------------------------------------------------

VaultFilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

export default function VaultFilterSidebar({
  getFilteredRecordingList,
  setFilterdata,
  isOpenFilter,
  onResetFilter,
  onOpenFilter,
  onCloseFilter,
  formik,
  setIsLoading,
  programOptions,
  categoryOptions,
  setProgramOptions,
  setCategoryOptions,
  onSubmitFilter,
}) {
  const handleChange = (e) => {
    const { name, checked, value } = e.target;
    if (name === "program") {
      let tempOption = programOptions.map((options) =>
        options._id === value ? { ...options, isChecked: checked } : options
      );
      setProgramOptions(tempOption);
    } else {
      let tempOption = categoryOptions.map((options) =>
        options._id === value ? { ...options, isChecked: checked } : options
      );
      setCategoryOptions(tempOption);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let programArray = [];
    programOptions.map((program, index) => {
      program.isChecked === true ? programArray.push(program._id) : program;
    });
    let categoryArray = [];
    categoryOptions.map((category, index) => {
      category.isChecked === true ? categoryArray.push(category._id) : category;
    });

    const dataObject = {
      program: programArray,
      vault_category: categoryArray,
    };
    setFilterdata(dataObject);
    onCloseFilter();
    onSubmitFilter(dataObject);
    setIsLoading(false);
  };

  return (
    <>
      <Button
        disableRipple
        sx={{ float: "right" }}
        color="inherit"
        endIcon={<Icon icon={roundFilterList} />}
        onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Drawer
            anchor="right"
            open={isOpenFilter}
            onClose={onCloseFilter}
            PaperProps={{
              sx: { width: 280, border: "none", overflow: "hidden" },
            }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ px: 1, py: 2 }}>
              <Typography variant="h6" sx={{ ml: 1 }}>
                Filters
              </Typography>
              <IconButton onClick={onCloseFilter}>
                <Icon icon={closeFill} width={20} height={20} />
              </IconButton>
            </Stack>

            <Divider />

            <Scrollbar>
              <Stack spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
                <div className="text-center">
                  {programOptions.length < 1 && categoryOptions.length < 1
                    ? "No Filter Found"
                    : ""}
                </div>
                {/* clients requirement is to hide this */}

                {/* {programOptions.length < 1 ? (
                  ""
                ) : (
                  <div>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold" }}
                      gutterBottom
                    >
                      {programOptions.length < 1 ? "" : "Programme"}
                    </Typography>
                    <FormGroup>
                      {programOptions.map((programOption, index) => (
                        <FormControlLabel
                          key={programOption._id}
                          control={
                            <Checkbox
                              value={programOption._id}
                              checked={programOption?.isChecked || false}
                              name="program"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          }
                          label={htmlDecode(programOption.title)}
                        />
                      ))}
                    </FormGroup>
                  </div>
                )} */}

                {categoryOptions.length < 1 ? (
                  ""
                ) : (
                  <div>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold" }}
                      gutterBottom>
                      {categoryOptions.length < 1 ? "" : "Category"}
                    </Typography>
                    <FormGroup>
                      {categoryOptions.map((categoryOption, index) => (
                        <FormControlLabel
                          key={categoryOption.vault_title}
                          control={
                            <Checkbox
                              value={categoryOption._id}
                              checked={categoryOption?.isChecked || false}
                              name="category"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          }
                          label={htmlDecode(categoryOption.vault_title)}
                        />
                      ))}
                    </FormGroup>
                  </div>
                )}
              </Stack>
            </Scrollbar>

            <Box sx={{ pl: 3, pr: 3, pt: 2 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                variant="outlined"
                onClick={handleSubmit}
                startIcon={
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: 5 }}>
                    <path
                      d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                      fill={get_root_value("--portal-theme-primary")}
                    />
                  </svg>
                }>
                Filter
              </Button>
            </Box>
            <Box sx={{ pl: 3, pr: 3, pt: 3, pb: 1 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                variant="outlined"
                onClick={onResetFilter}
                startIcon={<Icon icon={roundClearAll} />}>
                Clear All
              </Button>
            </Box>
          </Drawer>
        </Form>
      </FormikProvider>
    </>
  );
}
