import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MemoriesSlider } from "../../components/_dashboard/memories";
import { MomentDetailApi } from "src/DAL/Memories/Memories";
import { CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import GoalGallerySlider from "../GoalStatement/GoalGallerySlider";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function MemoriesDetail(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [memoriesData, setMemoriesData] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const getMemoryDetail = async () => {
    const memory_slug = params.memory_slug ? params.memory_slug : params.slug;
    const result = await MomentDetailApi(memory_slug);
    if (result.code === 200) {
      setMemoriesData(result.magicMoment);
      let galleryArray = [];
      let galleryObject = {};
      result.magicMoment.moment_image.map((gallery, index) => {
        galleryObject = {
          original: s3baseUrl + gallery.thumbnail_1,
          thumbnail: s3baseUrl + gallery.thumbnail_2,
        };
        galleryArray.push(galleryObject);
      });
      setSliderData(galleryArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/memories`);
    }
  };
  const handleClose = () => {
    // if (localStorage.getItem("search_location")) {
    //   navigate(`/search`);
    // } else {
    //   navigate(`/memories`);
    // }
    navigate(-1);
  };
  const highlightText = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parts = text.split(new RegExp(`(${storedSearchText})`, "gi"));
      return (
        <span>
          {parts.map((part, index) =>
            part.toLowerCase() === storedSearchText.toLowerCase() ? (
              <mark key={index} className="highlighted-text">
                {part}
              </mark>
            ) : (
              part
            )
          )}
        </span>
      );
    } else {
      return text;
    }
  };
  const highlightTextInDescription = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const parts = node.textContent.split(
            new RegExp(`(${storedSearchText})`, "gi")
          );
          const span = document.createElement("span");

          parts.forEach((part, index) => {
            if (index % 2 === 1) {
              const mark = document.createElement("mark");
              mark.className = "highlighted-text";
              mark.textContent = part;
              span.appendChild(mark);
            } else {
              span.appendChild(document.createTextNode(part));
            }
          });

          node.parentNode.replaceChild(span, node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          node.childNodes.forEach(processNode);
        }
      };

      processNode(doc.body);

      return doc.body.innerHTML;
    } else {
      return text;
    }
  };
  useEffect(() => {
    getMemoryDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton className="back-screen-button" onClick={handleClose}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-sm-12 col-md-8 col-lg-10">
          <h1>{highlightText(memoriesData.moment_title)}</h1>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-2 memories-date">
          <p className="text-md-end">{memoriesData.moment_date}</p>
        </div>
      </div>
      <div className="row media-margin">
        <div className="col-12">
          {/* <MemoriesSlider data={memoriesData.moment_image} /> */}
          <GoalGallerySlider data={sliderData} />
        </div>
        <div className="col-12 section-space">
          {/* <p>{htmlDecode(memoriesData.moment_description)}</p> */}
          <div
            dangerouslySetInnerHTML={{
              __html: highlightTextInDescription(
                htmlDecode(memoriesData.moment_description)
              ),
            }}></div>
        </div>
      </div>
    </div>
  );
}
