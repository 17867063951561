import React, { useState, useEffect } from "react";
import PinterestLayout from "src/components/PinterestLayout/Pinterest";
import { quoteSearch, quotesListing } from "../../DAL/Quotes/Quotes";
import { useSnackbar } from "notistack";
import { CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import CustomConfirmation from "src/components/CustomConfirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NoAccess } from "src/components/_dashboard/programmes";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function QuotesSearch() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [quotesData, setQuotesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { contentSettingData } = useContentSetting();
  const params = useParams();
  const getQuotesListing = async () => {
    const result = await quoteSearch(params.id);
    if (result.code === 200) {
      setQuotesData(result.quotation);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const highlightTextInDescription = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const parts = node.textContent.split(
            new RegExp(`(${storedSearchText})`, "gi")
          );
          const span = document.createElement("span");

          parts.forEach((part, index) => {
            if (index % 2 === 1) {
              const mark = document.createElement("mark");
              mark.className = "highlighted-text";
              mark.textContent = part;
              span.appendChild(mark);
            } else {
              span.appendChild(document.createTextNode(part));
            }
          });

          node.parentNode.replaceChild(span, node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          node.childNodes.forEach(processNode);
        }
      };

      processNode(doc.body);

      return doc.body.innerHTML;
    } else {
      return text;
    }
  };
  const handleDetailPage = () => {};
  const handleClose = () => {
    if (localStorage.getItem("search_location")) {
      navigate(`/search`);
    }
  };
  useEffect(() => {
    getQuotesListing();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <IconButton className="back-screen-button" onClick={handleClose}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-12 dashboard_description">
            {contentSettingData && contentSettingData.quotes_description ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: contentSettingData.quotes_description,
                }}></div>
            ) : (
              <h2 className="quotes-heading margin-quotes">Quotes</h2>
            )}
          </div>
        </div>
      </div>
      {quotesData.status ? (
        <div className="container">
          <div
            key={quotesData?._id}
            className="set-display-inline pinterest-card"
            id="columns">
            <figure>
              <img
                className="grid-item"
                src={s3baseUrl + quotesData?.quotation_images.thumbnail_1}
                alt=""
                onClick={() => handleDetailPage(quotesData?.pinterestSlug)}
              />
              {quotesData?.quotation_date && (
                <span>
                  {get_date_with_user_time_zone(
                    quotesData?.gratitude_date,
                    "DD MMM YYYY",
                    adminTimeZone,
                    userInfo.time_zone
                  )}
                </span>
              )}
              {/* {MENU_OPTIONS && (
              <CustomPopover menu={MENU_OPTIONS} data={quotesData} />
            )} */}
              <div
                dangerouslySetInnerHTML={{
                  __html: highlightTextInDescription(
                    htmlDecode(quotesData?.description)
                  ),
                }}
                className="programme-card-desc mt-0"
                onClick={() =>
                  handleDetailPage(quotesData?.pinterestSlug)
                }></div>
            </figure>
          </div>
        </div>
      ) : (
        <NoAccess title="Quote" />
      )}
    </>
  );
}
