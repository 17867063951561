import { Icon } from "@iconify/react";
import { useState } from "react";
import searchFill from "@iconify/icons-eva/search-fill";

// material
import { styled, alpha } from "@mui/material/styles";
import { Slide, IconButton } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled("div")(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  height: APPBAR_MOBILE,
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.52)}`,
  [theme.breakpoints.up("md")]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const [iconShow, setIconShow] = useState(true);
  const navigate = useNavigate();
  const params = useLocation();

  const handleNavigate = (podsSlug) => {
    localStorage.setItem("search_location", params.pathname);
    navigate(`/search`);
    setIconShow(false);
  };
  return (
    <div>
      {/* {iconShow && (
        // <IconButton onClick={handleOpen}>
        <IconButton onClick={handleNavigate}>
          <Icon icon={searchFill} width={20} height={20} />
        </IconButton>
      )} */}
      <IconButton onClick={handleNavigate}>
        <Icon icon={searchFill} width={20} height={20} />
      </IconButton>

      <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
        <SearchbarStyle></SearchbarStyle>
      </Slide>
    </div>
  );
}
