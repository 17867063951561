import {
  CircularProgress,
  IconButton,
  Stack,
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { filter } from "lodash";

import { useNavigate } from "react-router-dom";
import {
  marketPlaceDeleteApi,
  marketPlaceResubmitApi,
  marketPlaceListingClient,
  marketPlaceListingSpecificClient,
} from "src/DAL/MarketPlace/MarketPlace";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import CustomConfirmation from "src/components/CustomConfirmation";
import CustomPopover from "src/components/CustomPopover";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchNotFound from "src/components/SearchNotFound";
import Label from "src/components/Label";
import { UserListHead, UserListToolbar } from "src/components/_dashboard/user";
import { htmlDecode } from "src/utils/convertHtml";
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  // { id: "email", label: "Email", alignRight: false },
  { id: "action_by", label: "Added By", alignRight: false },
  { id: "postType", label: "Type", alignRight: false },
  { id: "created", label: "Created At", alignRight: false },
  // { id: "time", label: "Time", alignRight: false },
  { id: "is_approved", label: "Approval", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  //console.log(query, "query");
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      // ||
      // _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function ClientMarketPlace() {
  const navigate = useNavigate();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [resubmitDoc, setResubmitDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openResubmit, setOpenResubmit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [marketList, setMarketList] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [filterName, setFilterName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  const handleRequestSort = (event, property) => {};
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };
  const handleSearchName = () => {
    getMarketPlace();
  };
  const emptyRows = page > 0 && 0;
  // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - marketList.length) : 0;
  const filteredUsers = applySortFilter(
    marketList,
    getComparator(order, orderBy)
  );
  const handleEdit = (value) => {
    navigate(`/market-place/edit-post/${value._id}`, {
      state: value,
    });
  };
  const handleDetail = (value) => {
    navigate(`/my-post/post-detail/${value._id}`, {
      state: value,
    });
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleAgreeResubmit = (value) => {
    console.log(value, "value");
    setOpenResubmit(true);
    setResubmitDoc(value);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await marketPlaceDeleteApi(deleteDoc._id);
    if (result.code === 200) {
      getMarketPlace();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleResubmit = async (e) => {
    e.preventDefault();
    setOpenResubmit(false);
    const ExpiryDate = new Date(resubmitDoc.expiry_date);
    const yearExpiry = ExpiryDate.getFullYear();
    const monthExpiry = String(ExpiryDate.getMonth() + 1).padStart(2, "0");
    const dayExpiry = String(ExpiryDate.getDate()).padStart(2, "0");
    const formattedExpiryDate = `${yearExpiry}-${monthExpiry}-${dayExpiry}`;
    const postData = {
      first_name: resubmitDoc?.first_name,
      last_name: resubmitDoc?.last_name,
      title: resubmitDoc?.title,
      post_type: resubmitDoc?.post_type,
      status: resubmitDoc?.status,
      // order: resubmitDoc.order ? resubmitDoc.order : 0,
      expiry_date: formattedExpiryDate,
    };

    if (resubmitDoc.post_type == "event") {
      // postData.title = resubmitDoc?.title;
      postData.link = resubmitDoc.link;
      postData.button_text = resubmitDoc?.button_text;
      postData.time = resubmitDoc.time;
      postData.date = resubmitDoc.date;
      postData.short_description = resubmitDoc.short_description;
      postData.timezone = resubmitDoc.timezone;
    }
    if (resubmitDoc.post_type == "product") {
      postData.button_text = resubmitDoc.button_text;
      postData.link = resubmitDoc.link;
      postData.short_description = resubmitDoc.short_description;
    }

    setIsLoading(true);
    const result = await marketPlaceResubmitApi(postData, resubmitDoc._id);
    if (result.code === 200) {
      getMarketPlace();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getMarketPlace = async () => {
    setIsLoading(true);
    let postData = {
      // approved: "all",
      action_by: "client",
      search_text: filterName,
      // status: "all",
    };

    try {
      setIsLoading(true);
      const result = await marketPlaceListingSpecificClient(
        postData,
        page,
        rowsPerPage
      );
      if (result.code === 200) {
        const data = result.post.map((record, index) => {
          const dateParts = record?.createdAt.split("-");
          const year = parseInt(dateParts[0], 10);
          const month = parseInt(dateParts[1], 10);
          const day = parseInt(dateParts[2], 10);
          let typeOfPost = "";
          if (record?.post_type == "event") {
            typeOfPost = "Event Feature";
          }
          if (record?.post_type == "product") {
            typeOfPost = "Product Feature";
          }
          return {
            ...record,
            name: record?.first_name + " " + record?.last_name,
            created: year + "-" + month + "-" + day,
            postType: typeOfPost,
            count: index + 1 + rowsPerPage * page,
          };
        });
        setMarketList(data);
        setTotalCount(result.post_count);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getMarketPlace();
  }, [rowsPerPage, page]);
  const isUserNotFound = filteredUsers.length === 0;

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Detail",
      icon: "majesticons:checkbox-list-detail",
      handleClick: handleDetail,
    },
  ];
  const MENU_OPTIONS_APPROVED = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Detail",
      icon: "majesticons:checkbox-list-detail",
      handleClick: handleDetail,
    },
  ];
  const MENU_OPTIONS_REJECT = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Detail",
      icon: "majesticons:checkbox-list-detail",
      handleClick: handleDetail,
    },
    {
      label: "ReSubmit",
      icon: "clarity:repeat-line",
      handleClick: handleAgreeResubmit,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <h2>My Posts</h2>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-6 col-sm-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-lg-6 col-sm-12 text-end">
          <button
            onClick={() => navigate(`/market-place/add-post`)}
            className="small-contained-button">
            Add Post
          </button>
        </div>
      </div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}></Stack>
      <Card style={{ overflowX: "auto" }}>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          onSearch={handleSearchName}
        />

        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={marketList.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  const {
                    id,
                    name,
                    status,
                    lastName,
                    // email,
                    profile,
                    goal,
                    action_by,
                    postType,
                    is_approved,
                    created,
                    time,
                    reject_reason,
                  } = row;
                  const isItemSelected = selected.indexOf(name) !== -1;
                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}>
                      <TableCell
                        align="left"
                        onClick={() => handleDetail(row)}
                        className="cursor-table">
                        {page * rowsPerPage + (i + 1)}
                      </TableCell>
                      <TableCell>
                        {row?.is_approved === "approved" ? (
                          <>
                            <CustomPopover
                              menu={MENU_OPTIONS_APPROVED}
                              data={row}
                            />
                          </>
                        ) : (
                          <>
                            {row?.is_approved === "rejected" ? (
                              <CustomPopover
                                menu={MENU_OPTIONS_REJECT}
                                data={row}
                              />
                            ) : (
                              <CustomPopover menu={MENU_OPTIONS} data={row} />
                            )}
                          </>
                        )}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => handleDetail(row)}
                        className="cursor-table">
                        {name}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        {htmlDecode(email)}
                      </TableCell> */}
                      <TableCell component="th" scope="row">
                        {Capitalize(htmlDecode(action_by))}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {Capitalize(htmlDecode(postType))}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {created}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        {time}
                      </TableCell> */}

                      <TableCell align="left" className="custom-tooltip">
                        <Label
                          variant="ghost"
                          color={
                            is_approved === "rejected"
                              ? "error"
                              : is_approved === "pending"
                              ? "warning"
                              : "success"
                          }
                          title={
                            is_approved === "rejected"
                              ? String(reject_reason)
                              : ""
                          }>
                          {is_approved === "rejected"
                            ? "Rejected"
                            : is_approved === "pending"
                            ? "Pending"
                            : "Approved"}
                        </Label>
                        {/* {is_approved === "rejected" && (
                            <div className="tooltip-text">{reject_reason}</div>
                          )} */}
                      </TableCell>

                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={status === false ? "error" : "success"}>
                          {status === false ? "InActive" : "Active"}
                        </Label>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[50, 100, 150]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openResubmit}
        setOpen={setOpenResubmit}
        title={"Are you sure you want to Resubmit?"}
        handleAgree={handleResubmit}
      />
    </div>
  );
}
