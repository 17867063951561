import React, { useState, useEffect } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { ReactVideoPlayer } from "src/components";
import Chip from "@mui/material/Chip";
import { s3baseUrl } from "src/config/config";
import { vaultSearch } from "src/DAL/Programmes/Programmes";
import { makeStyles } from "@mui/styles";
import { NoAccess } from "src/components/_dashboard/programmes";
import { htmlDecode } from "src/utils/convertHtml";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function VaultSearch() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [recordingsList, setRecordingsList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  const RecordingSearch = async () => {
    setIsLoading(true);
    const result = await vaultSearch(params.slug);
    if (result.code === 200) {
      setRecordingsList(result.recording);
      setIsLoading(false);
    } else {
      // enqueueSnackbar(result.message, { variant: "error" });
      setIsActive(true);
      setIsLoading(false);
    }
  };
  const handleClose = () => {
    // if (localStorage.getItem("search_location")) {
    //   navigate(`/search`);
    // } else {
    //   navigate(`/vault`);
    // }
    navigate(-1);
  };
  const highlightText = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parts = text.split(new RegExp(`(${storedSearchText})`, "gi"));
      return (
        <span>
          {parts.map((part, index) =>
            part.toLowerCase() === storedSearchText.toLowerCase() ? (
              <mark key={index} className="highlighted-text">
                {part}
              </mark>
            ) : (
              part
            )
          )}
        </span>
      );
    } else {
      return text;
    }
  };
  const highlightTextInDescription = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const parts = node.textContent.split(
            new RegExp(`(${storedSearchText})`, "gi")
          );
          const span = document.createElement("span");

          parts.forEach((part, index) => {
            if (index % 2 === 1) {
              const mark = document.createElement("mark");
              mark.className = "highlighted-text";
              mark.textContent = part;
              span.appendChild(mark);
            } else {
              span.appendChild(document.createTextNode(part));
            }
          });

          node.parentNode.replaceChild(span, node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          node.childNodes.forEach(processNode);
        }
      };

      processNode(doc.body);

      return doc.body.innerHTML;
    } else {
      return text;
    }
  };
  useEffect(() => {
    RecordingSearch();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(recordingsList, "recordingsList");
  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-12">
          <IconButton className="back-screen-button" onClick={handleClose}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-12">
          <h2>Replay Library</h2>
        </div>
      </div>
      {recordingsList.is_locked || isActive ? (
        <NoAccess title="Recording" />
      ) : (
        <div className="card recordings-card h-100 secondary-background">
          <div className="row section-space">
            <div className="col-10">
              <h3 className="programmes-heading">
                {highlightText(recordingsList?.title)}
              </h3>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 text-end">
              <p className="vault-date text-secondary">
                {/* {recordingsList?.recording_date} */}
                {moment(recordingsList?.recording_date).format("DD MMM YYYY")}
              </p>
            </div>
          </div>
          <div className="row media-margin">
            <div className="col-12">
              <ReactVideoPlayer url={recordingsList?.video_url} />
            </div>

            {recordingsList?.audio_recording && (
              <div className="col-12 mt-3 text-center">
                <div>
                  <audio
                    controlsList="nodownload"
                    className="w-100"
                    src={s3baseUrl + recordingsList?.audio_recording}
                    controls
                  />
                </div>
              </div>
            )}
            <div className="col-12 section-space">
              {/* <p>{htmlDecode(recordingsList?.short_description)}</p> */}
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightTextInDescription(
                    recordingsList?.short_description
                  ),
                }}></span>
            </div>
            <div className="col-sm-12 col-md-7 program-btn">
              <button className="small-contained-button ">
                {htmlDecode(recordingsList?.program?.title)}
              </button>
            </div>
            <div className="col-sm-12 col-md-5 program-category-btn">
              {recordingsList?.vault_category?.vault_title && (
                <Chip
                  label={htmlDecode(
                    recordingsList?.vault_category?.vault_title
                  )}
                  color="primary"
                  className="me-1"
                  variant="outlined"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
