import React, { useState, useEffect } from "react";
import { IconButton, CircularProgress, Chip, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { PodsDetailApi } from "src/DAL/Pods/Pods";
import { s3baseUrl } from "src/config/config";
import ReactTooltip from "react-tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { htmlDecode } from "src/utils/convertHtml";
import { NoAccess } from "src/components/_dashboard/programmes";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const PodsDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [podsData, setPodsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const getMemoryDetail = async () => {
    console.log(params, "pods_slug");
    const pod_slug = params.pod_slug ? params.pod_slug : params.slug;
    const result = await PodsDetailApi(pod_slug);
    if (result.code === 200) {
      setPodsData(result.room);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/pods`);
    }
  };
  const setCopiedPassword = () => {
    enqueueSnackbar("Password Copied To Clipboard", { variant: "success" });
    setCopied(true);
  };
  const highlightText = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parts = text.split(new RegExp(`(${storedSearchText})`, "gi"));
      return (
        <span>
          {parts.map((part, index) =>
            part.toLowerCase() === storedSearchText.toLowerCase() ? (
              <mark key={index} className="highlighted-text">
                {part}
              </mark>
            ) : (
              part
            )
          )}
        </span>
      );
    } else {
      return text;
    }
  };
  const highlightTextInDescription = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const parts = node.textContent.split(
            new RegExp(`(${storedSearchText})`, "gi")
          );
          const span = document.createElement("span");

          parts.forEach((part, index) => {
            if (index % 2 === 1) {
              const mark = document.createElement("mark");
              mark.className = "highlighted-text";
              mark.textContent = part;
              span.appendChild(mark);
            } else {
              span.appendChild(document.createTextNode(part));
            }
          });

          node.parentNode.replaceChild(span, node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          node.childNodes.forEach(processNode);
        }
      };

      processNode(doc.body);

      return doc.body.innerHTML;
    } else {
      return text;
    }
  };
  useEffect(() => {
    getMemoryDetail();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      {podsData.is_locked || !podsData.status ? (
        <>
          <div className="container">
            <div className="row mobile-margin display-flex">
              <div className="col-12">
                <IconButton
                  className="back-screen-button"
                  onClick={() => navigate(-1)}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <NoAccess title="Link" />
            </div>
          </div>
        </>
      ) : (
        <div className="container">
          <div className="row mobile-margin display-flex">
            <div className="col-12">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton>
              {podsData.zoom_link && (
                <a
                  href={podsData.zoom_link}
                  target="_blank"
                  rel="noopener noreferrer">
                  <button className="small-contained-button float-end mt-1">
                    Join Meeting
                  </button>
                </a>
              )}
            </div>
          </div>

          <div className="row section-space">
            <div className="col-12 zoom-password">
              <h1 className="d-inline">{highlightText(podsData.title)}</h1>
              {podsData.password && (
                <CopyToClipboard
                  text={podsData.password}
                  onCopy={() => setCopiedPassword(true)}>
                  <Tooltip title="Click to copy password">
                    <Chip
                      label={
                        <>
                          {podsData.password}
                          <LockIcon />
                        </>
                      }
                      color="primary"
                      className="float-end me-1"
                      variant="outlined"
                    />
                  </Tooltip>
                </CopyToClipboard>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              {/* <p className="mt-3">{htmlDecode(podsData.short_description)}</p> */}
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightTextInDescription(
                    podsData?.short_description
                  ),
                }}></span>
            </div>
            <div className="col-12">
              <img
                width="100%"
                src={s3baseUrl + podsData.room_image.thumbnail_1}
              />
            </div>
            <div className="col-12 section-space">
              <div
                dangerouslySetInnerHTML={{
                  __html: highlightTextInDescription(
                    podsData.detail_description
                  ),
                }}></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PodsDetail;
