import moment from "moment";
import React, { useEffect, useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";

export default function Announcement() {
  const [greeting, setGreeting] = useState("Hello");
  const { userInfo, dashboardSettingData } = useContentSetting();
  const handleClick = () => {
    window.open(dashboardSettingData?.anouncement_button_link, "_blank");
  };
  useEffect(() => {
    var currentHour = moment().format("HH");

    if (currentHour >= 6 && currentHour < 12) {
      setGreeting("Good Morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting("Good Afternoon");
    } else if (currentHour >= 18 && currentHour < 21) {
      setGreeting("Good Evening");
    } else {
      setGreeting("Good Night");
    }
  }, []);
  return (
    <div className="announcement-card">
      <div className="row">
        <div className="col-lg-5 col-12">
          <h4 style={{ color: "#fff", fontSize: "15px" }}>
            {greeting}, {userInfo?.first_name + " " + userInfo?.last_name}!
          </h4>
        </div>
        <div className="col-lg-7 col-12 text-end">
          <button className="announcement-button" onClick={handleClick}>
            {dashboardSettingData?.anouncement_button_text}
          </button>
        </div>

        <div className="col-12">
          <div
            className="announcement-text"
            dangerouslySetInnerHTML={{
              __html: dashboardSettingData?.anouncement_description,
            }}></div>
        </div>
      </div>
    </div>
  );
}
