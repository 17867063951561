import { Avatar, Box, List } from "@mui/material";
import { ContentSettingApi } from "src/DAL/ContentSetting/ContentSetting";
import { memberDetail } from "src/DAL/Profile/Profile";
import NavSectionItem from "./NavSectionItem";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";
export default function NavSection({ navConfig }) {
  const location = useLocation();
  const is_seen_video = _get_is_seen_video_localStorage();

  const {
    handleContentSettingData,
    handleDashboardSettingData,
    handleAnnouncement,
    handleDashboardTestimonialData,
    handleDashboardEventData,
    handleAdminTimeZone,
    handleUserInfo,
    handleConsultantInfo,
    navbarList,
    dashboardSettingData,
    consultantInfo,
  } = useContentSetting();

  const getContentSetting = async () => {
    const result = await ContentSettingApi();
    console.log(result, "resultresultresult");
    if (result.code === 200) {
      if (result.content_setting === null) {
        handleDashboardSettingData({});
        handleAnnouncement("");
        handleContentSettingData({});
        handleDashboardTestimonialData([]);
        handleDashboardEventData([]);
        handleAdminTimeZone("");
        return;
      }
      localStorage.setItem(
        `content_setting`,
        JSON.stringify(result.content_setting.client_content_setting)
      );
      localStorage.setItem(
        `client_dashboard_setting`,
        JSON.stringify(result.content_setting.client_dashboard_setting)
      );
      localStorage.setItem(
        `announcement`,
        result.content_setting.client_dashboard_setting.anouncement_status ==
          true
          ? "yes"
          : "no"
      );
      localStorage.setItem(`testmonial`, JSON.stringify(result.testmonial));
      localStorage.setItem(`home_event`, JSON.stringify(result.home_event));
      localStorage.setItem(`admin_time_zone`, JSON.stringify(result.time_zone));
      handleAdminTimeZone(result.time_zone);
      handleDashboardEventData(result.home_event);
      handleDashboardTestimonialData(result.testmonial);
      handleContentSettingData(result.content_setting.client_content_setting);
      handleDashboardSettingData(
        result.content_setting.client_dashboard_setting
      );
      handleAnnouncement(
        result.content_setting.client_dashboard_setting.anouncement_status ==
          true
          ? "yes"
          : "no"
      );
      if (
        dashboardSettingData?.video_url !==
          result.content_setting?.client_dashboard_setting?.video_url ||
        result.content_setting?.client_dashboard_setting?.video_type == "repeat"
      ) {
        localStorage.setItem("is_seen_video", "No");
      }
      ReactGA.initialize(
        result.content_setting.client_general_setting.analytic_google_code,
        { debug: true }
      );
      const member_result = await memberDetail();
      if (member_result.code === 200) {
        localStorage.setItem(`user_data`, JSON.stringify(member_result.member));
        localStorage.setItem(
          `consultant_data`,
          JSON.stringify(member_result.member_consultant)
        );
        handleUserInfo(member_result.member);
        handleConsultantInfo(member_result.member_consultant);
        if (is_seen_video && is_seen_video === "Yes") {
        } else {
          localStorage.setItem("is_seen_video", "No");
        }
      } else {
        enqueueSnackbar(member_result.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getContentSetting();
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    console.log(location.pathname, "location.pathname");
    console.log(location.search, "location.search");
    // const sidebarName = getSidebarNameForPath(location.pathname);

    // Send the sidebar name as a custom event to Google Analytics
  }, [location]);

  return (
    <Box>
      <List disablePadding>
        {navConfig.map((item, i) => {
          return <NavSectionItem data={item} />;
        })}
      </List>
      {consultantInfo !== null &&
        consultantInfo !== undefined &&
        consultantInfo !== "undefined" &&
        consultantInfo.team_type !== "team" &&
        consultantInfo.status === true && (
          <div className="associate-section">
            <p className="mt-3">Your Associate is: </p>
            <div className="d-flex circle-image">
              <Avatar
                src={s3baseUrl + consultantInfo?.image.thumbnail_1}
                alt="photoURL">
                {consultantInfo?.first_name[0] + consultantInfo?.last_name[0]}
              </Avatar>
              <p className="card-title mt-3 mb-0">
                {consultantInfo?.first_name + " " + consultantInfo?.last_name}
              </p>
            </div>
          </div>
        )}
    </Box>
  );
}
