import WebHeader from "./WebHeader";
import WebFooter from "./WebFooter";
import GeneralFooter from "./GeneralFooter";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import {
  add_free_member_by_web,
  confirm_one_time_payment_for_web,
  confirm_subscription_incomplete_for_web,
  get_web_intent_client_secret_for_one_time,
  paymentPlanDetail,
  pay_now_for_subscription_web,
  websitePagesContent,
} from "src/DAL/WebPages/WebPages";
import { convertCurrencyToSign } from "src/utils/constants";
import countryList from "react-select-country-list";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PaymentForm() {
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const stripe = useStripe();
  const classes = useStyles();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const [pageData, setPageData] = useState({});
  const [paymentPlan, setPaymentPlan] = useState([]);
  const [clientSecret, setClientSecret] = useState("");
  const [resPostData, setResPostData] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const [inputState, setInputsState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    country: "CA",
    password: "",
    passwordType: "password",
    aboutUs: "",
  });

  const handleChangeInputsState = (e) => {
    const { name, value } = e.target;
    setInputsState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getPlanDetail = async () => {
    const result = await paymentPlanDetail(params.plan_slug);
    if (result.code === 200) {
      setPaymentPlan(result.payment_plan);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getWebsitePagesContent = async () => {
    const result = await websitePagesContent(
      JSON.stringify({ page_slug: params.page_slug })
    );
    if (result.code === 200) {
      setPageData(result.Sale_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const payNowForSubscription = async (token, cardElement) => {
    const formData = new FormData();
    formData.append("last_name", inputState.lastName);
    formData.append("email", inputState.email);
    formData.append("password", inputState.password);
    formData.append("contact_number", inputState.phone);
    formData.append("street", inputState.streetAddress);
    formData.append("city", inputState.city);
    formData.append("zip_code", inputState.zipCode);
    formData.append("state", inputState.state);
    formData.append("country", inputState.country);
    formData.append("first_name", inputState.firstName);
    formData.append("page_slug", params.page_slug);
    formData.append("plan_id", paymentPlan._id);
    formData.append("source_token", token);
    formData.append(
      "affliliate_user_name",
      params.affiliate_name ? params.affiliate_name : ""
    );

    // if client secret is already generated
    if (clientSecret && resPostData) {
      handleSecureCard(clientSecret, cardElement, resPostData);
      return;
    }

    const result = await pay_now_for_subscription_web(formData);
    if (result.code === 200) {
      setIsLoadingCard(false);
      enqueueSnackbar("Payment succeeded successfully.", {
        variant: "success",
      });
      navigate(
        `/${params.page_slug}/thank-you/${params.plan_slug}/${result.affiliate_member.affiliate_url_name}`
      );
    } else if (result.code === 210) {
      const formData = new FormData();
      formData.append("page_slug", params.page_slug);
      formData.append("plan_id", paymentPlan._id);
      formData.append("recurring_price_id", result.strip_recurring_price_id);
      formData.append("subscription_id", result.strip_subscription_id);
      formData.append("email", inputState.email);
      formData.append(
        "affliliate_user_name",
        params.affiliate_name ? params.affiliate_name : ""
      );
      // save data for failure use
      setClientSecret(result.client_secret);
      setResPostData(formData);
      // handle secure card action
      handleSecureCard(result.client_secret, cardElement, formData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const getIntentClientSecretForOneTime = async (token, cardElement) => {
    const formData = new FormData();
    formData.append("last_name", inputState.lastName);
    formData.append("email", inputState.email);
    formData.append("password", inputState.password);
    formData.append("contact_number", inputState.phone);
    formData.append("street", inputState.streetAddress);
    formData.append("city", inputState.city);
    formData.append("zip_code", inputState.zipCode);
    formData.append("state", inputState.state);
    formData.append("country", inputState.country);
    formData.append("first_name", inputState.firstName);
    formData.append("plan_id", paymentPlan._id);
    formData.append("about_us", inputState.aboutUs);
    // formData.append("page_slug", params.page_slug);
    formData.append(
      "affliliate_user_name",
      params.affiliate_name ? params.affiliate_name : ""
    );

    // if client secret is already generated
    if (clientSecret && resPostData) {
      handleSecureCard(clientSecret, cardElement, resPostData);
      return;
    }

    const result = await get_web_intent_client_secret_for_one_time(formData);
    console.log(...formData, "formDataaaaaa");
    if (result.code === 200) {
      // confirm payment data
      const formData = new FormData();
      formData.append("email", inputState.email);
      formData.append("page_slug", params.page_slug);
      formData.append("plan_id", paymentPlan._id);
      formData.append(
        "affliliate_user_name",
        params.affiliate_name ? params.affiliate_name : ""
      );
      // save data for failure use
      setClientSecret(result.client_secret);
      setResPostData(formData);
      // handle secure card action
      handleSecureCard(result.client_secret, cardElement, formData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const confirmCardPayment = async (postData) => {
    if (paymentPlan.payment_access === "recursion") {
      const result = await confirm_subscription_incomplete_for_web(postData);

      if (result.code === 200) {
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        navigate(
          `/${params.page_slug}/thank-you/${params.plan_slug}/${result.affiliate_member.affiliate_url_name}`
        );
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
      }
    } else {
      const result = await confirm_one_time_payment_for_web(postData);
      if (result.code === 200) {
        setIsLoadingCard(false);
        console.log(result, "resultresultresult");
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        navigate(
          `/${params.page_slug}/thank-you/${params.plan_slug}/${result.affiliate_member.affiliate_url_name}`
        );
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
      }
    }
  };

  const handleSecureCard = (client_secret, cardElement, postData) => {
    enqueueSnackbar("Processing card...", {
      variant: "info",
    });

    stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        // Handle result.error or result.paymentIntent
        if (result.error) {
          setIsLoadingCard(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }

        confirmCardPayment(postData);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const handleCardAction = (card_token, cardElement) => {
    if (paymentPlan.payment_access === "recursion") {
      payNowForSubscription(card_token, cardElement);
    } else {
      getIntentClientSecretForOneTime(card_token, cardElement);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    setIsLoadingCard(true);
    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement).then(function (result) {
      // Handle result.error or result.token
      if (result.error) {
        setIsLoadingCard(false);
        enqueueSnackbar(result.error.message, { variant: "error" });
        return;
      }
      handleCardAction(result.token.id, cardElement);
    });
  };

  const handleSubmitFree = async (event) => {
    event.preventDefault();
    setIsLoadingCard(true);
    const formData = new FormData();
    formData.append("last_name", inputState.lastName);
    formData.append("email", inputState.email);
    formData.append("password", inputState.password);
    formData.append("contact_number", inputState.phone);
    formData.append("street", inputState.streetAddress);
    formData.append("city", inputState.city);
    formData.append("zip_code", inputState.zipCode);
    formData.append("state", inputState.state);
    formData.append("country", inputState.country);
    formData.append("first_name", inputState.firstName);
    formData.append("plan_id", paymentPlan._id);
    formData.append("page_slug", params.page_slug);
    formData.append(
      "affliliate_user_name",
      params.affiliate_name ? params.affiliate_name : ""
    );

    for (var value of formData.values()) {
      console.log(value, "form data value");
    }
    const result = await add_free_member_by_web(formData);
    if (result.code === 200) {
      setIsLoadingCard(false);
      enqueueSnackbar(result.message, {
        variant: "success",
      });
      navigate(
        `/${params.page_slug}/thank-you/${params.plan_slug}/${result.affiliate_member.affiliate_url_name}`
      );
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const changePasswordType = () => {
    if (inputState.passwordType === "password") {
      setInputsState((prevState) => ({
        ...prevState,
        ["passwordType"]: "text",
      }));
    } else {
      setInputsState((prevState) => ({
        ...prevState,
        ["passwordType"]: "password",
      }));
    }
  };

  useEffect(() => {
    getWebsitePagesContent();
    getPlanDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(paymentPlan, "paymentPlan");

  return (
    <div className="landing-page">
      <WebHeader data={pageData} />
      {paymentPlan.is_plan_free === false && (
        <section className="wb_time_wrapper">
          <div className="container">
            <div className="row justify-content-center mt-3 mt-md-5">
              <div className="col-md-10 col-lg-8">
                <div className="wb_outer-div">
                  <div className="wb_black-div d-flex justify-content-between">
                    <p>{paymentPlan.plan_title}</p>
                    <p>
                      {convertCurrencyToSign(paymentPlan.plan_currency) +
                        paymentPlan.plan_price}
                    </p>
                  </div>
                  <div className="wb_inner-div d-flex justify-content-between">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: paymentPlan.detailed_description,
                      }}
                    ></div>
                    <div>
                      <p>
                        {convertCurrencyToSign(paymentPlan.plan_currency) +
                          paymentPlan.plan_price}
                      </p>
                      <p>All prices in {paymentPlan.plan_currency}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="contact-us paymet-form">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-10 col-lg-8">
              <form
                onSubmit={
                  paymentPlan.is_plan_free === false
                    ? handleSubmit
                    : handleSubmitFree
                }
              >
                <div className="row mt-4">
                  <div className="col-12">
                    <h2>Personal Information:</h2>
                  </div>
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="firstName"
                      value={inputState.firstName}
                      required
                      onChange={handleChangeInputsState}
                      className="form-control"
                      placeholder="First Name: *"
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="lastName"
                      value={inputState.lastName}
                      required
                      onChange={handleChangeInputsState}
                      className="form-control"
                      placeholder="Last Name: *"
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={inputState.email}
                      required
                      onChange={handleChangeInputsState}
                      placeholder="Email: *"
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 paymet-form-password">
                    <input
                      type={inputState.passwordType}
                      className="form-control"
                      name="password"
                      value={inputState.password}
                      required
                      onChange={handleChangeInputsState}
                      placeholder="Password: *"
                    />
                    <span
                      className="paymet-form-password-icon"
                      onClick={() => {
                        changePasswordType();
                      }}
                    >
                      <i className="fa-solid fa-eye"></i>
                    </span>
                  </div>
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Phone Number:"
                      name="phone"
                      value={inputState.phone}
                      onChange={handleChangeInputsState}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="How did you hear about us:"
                      name="aboutUs"
                      value={inputState.aboutUs}
                      onChange={handleChangeInputsState}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <h2>Billing Address:</h2>
                  </div>
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="streetAddress"
                      value={inputState.streetAddress}
                      required
                      onChange={handleChangeInputsState}
                      placeholder="Street Address: *"
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={inputState.city}
                      required
                      onChange={handleChangeInputsState}
                      placeholder="City: *"
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      value={inputState.state}
                      required
                      onChange={handleChangeInputsState}
                      placeholder="State: *"
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="zipCode"
                      value={inputState.zipCode}
                      required
                      onChange={handleChangeInputsState}
                      placeholder="Zip Code: *"
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3">
                    <select
                      data-restore-id="field-customer-address-country"
                      data-restore-name="country"
                      name="country"
                      className="form-control"
                      value={inputState.country}
                      onChange={handleChangeInputsState}
                    >
                      {options &&
                        options.map((option_value, i) => {
                          return (
                            <option
                              value={option_value.value}
                              selected={
                                option_value.value === inputState.country &&
                                "selected"
                              }
                            >
                              {option_value.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {paymentPlan.is_plan_free === false && (
                    <>
                      <div className="col-12 mt-3">
                        <h2>Card Details:</h2>
                      </div>
                      <div className="col-12">
                        <div className="card p-3 mt-4 mt-md-0">
                          <CardElement
                            options={{
                              hidePostalCode: true,
                              style: {
                                base: {
                                  padding: "20px",
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="learn-btn-payment">
                    {isLoadingCard ? (
                      <button disabled={true} className="w-100">
                        Processing...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="w-100"
                        disabled={!stripe || !elements}
                      >
                        PURCHASE NOW
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <GeneralFooter data={pageData} setIsLoading={setIsLoadingCard} />
      {/* fontawesome */}
    </div>
  );
}
