import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { Box, Card, Stack, IconButton, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  StudySessionLis,
  StudySessionDetail,
} from "../../../DAL/Programmes/Programmes";
import { s3baseUrl } from "../../../config/config";
import { RecordNotFound } from "src/components/_dashboard/programmes";
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

export default function StudySessionListing() {
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [recoringsData, setRecoringsData] = useState([]);
  const [studySessionDetail, setStudySessionDetail] = useState({});

  const getStudySessionDetail = async () => {
    const result = await StudySessionDetail(params.sesson_slug);
    if (result.code === 200) {
      setStudySessionDetail(result.study_session);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programs`);
    }
  };

  const getStudySessionListing = async () => {
    const result = await StudySessionLis(params.sesson_slug);
    if (result.code === 200) {
      setRecoringsData(result.study_session_recording);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programs`);
    }
  };

  useEffect(() => {
    getStudySessionListing();
    getStudySessionDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>
        <div className="row section-space">
          <div className="col-12">
            <h1>{studySessionDetail.title}</h1>
          </div>
        </div>
        <div className="row">
          {recoringsData.length > 0 ? (
            recoringsData.map((recoring, i) => (
              <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                <Card
                  className="programm-card h-100"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/study-session/${params.sesson_slug}/study-session-recording/${recoring.study_session_recording_slug}`
                    )
                  }
                >
                  <Box
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <ProgrammesImgStyle
                      alt="lessons"
                      src={
                        s3baseUrl +
                        recoring.session_recording_images.thumbnail_2
                      }
                    />
                  </Box>

                  <Stack spacing={2} className="lesson-content">
                    <h3>{recoring.title}</h3>
                    <p className="programme-card-desc">
                      {recoring.short_description}
                    </p>
                  </Stack>
                </Card>
              </div>
            ))
          ) : (
            <RecordNotFound title="Study Session Recordings Not Found" />
          )}
        </div>
      </div>
    </>
  );
}
