import { websitePagesContent } from "src/DAL/WebPages/WebPages";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LandingPage1 from "./LandingPage1";
import { useParams } from "react-router-dom";
import Page404 from "../Page404";
import FaqPage from "./FaqPage";
import TeamPage from "./TeamPage";
import ContactUs from "./ContactUs";
import MorningStudyClub from "./MorningStudyClub";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function LandingPage() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [pageData, setPageData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageName, setPageName] = useState("");
  const [testimonials, setTestimonials] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [FAQs, setFAQs] = useState([]);
  const [paymentPlan, setPaymentPlan] = useState([]);
  const classes = useStyles();

  const getWebsitePagesContent = async () => {
    var get_page_slug = {};
    if (params.page_slug) {
      get_page_slug = { page_slug: params.page_slug };
    } else {
      get_page_slug = { page_slug: "" };
    }
    const result = await websitePagesContent(JSON.stringify(get_page_slug));
    if (result.code === 200) {
      setPageData(result.Sale_page);
      setPageName(result.Sale_page.page_component_name);
      setTestimonials(result.testimonial);
      setTeamData(result.team);
      if (result.payment_plan) {
        setPaymentPlan(result.payment_plan[0]);
      }
      setFAQs(result.faq);
      setIsLoading(false);
    } else {
      setPageName(undefined);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getWebsitePagesContent();
  }, [params.page_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      {pageName === "" || pageName === "home" ? (
        <LandingPage1
          pageData={pageData}
          testimonials={testimonials}
          FAQs={FAQs}
          paymentPlan={paymentPlan}
        />
      ) : pageName === "faq" ? (
        <FaqPage
          pageData={pageData}
          faqData={FAQs}
          setIsLoading={setIsLoading}
        />
      ) : pageName === "team" ? (
        <TeamPage pageData={pageData} teamData={teamData} />
      ) : pageName === "contact-us" ? (
        <ContactUs pageData={pageData} />
      ) : pageName === "morning-study-club" ? (
        <MorningStudyClub
          pageData={pageData}
          faqData={FAQs}
          testimonials={testimonials}
          paymentPlan={paymentPlan}
          setIsLoading={setIsLoading}
        />
      ) : (
        <Page404 />
      )}
    </>
  );
}
export default LandingPage;
