import React, { useState } from "react";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  UploadGalleryImageOnS3,
  AddGalleryImage,
} from "src/DAL/GoalStatement/GoalStatementList";
import { AddSelfGalleryImage } from "src/DAL/SelfImage/SelfImageList";

export default function AddPhotos({ onCloseDrawer, dataList }) {
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemove = (index) => {
    previews.splice(index, 1);
    files.splice(index, 1);
    setPreviews([...previews]);
    setFiles([...files]);
  };

  const handleUpload = (event) => {
    const fileList = event.target.files;
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const preview = URL.createObjectURL(fileList[i]);
      setFiles((prevFiles) => [...prevFiles, file]);
      setPreviews((prevPreviews) => [...prevPreviews, preview]);
    }
  };

  const UploadImages = async (formData) => {
    const result = await UploadGalleryImageOnS3(formData);
    return result.image_path;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (files.length < 1) {
      setIsLoading(false);
      enqueueSnackbar("Please select at least 1 image", { variant: "error" });
    } else {
      const results = files.map((image, index) => {
        const formData = new FormData();
        formData.append("image", image);
        formData.append("width", 1000);
        const result = UploadImages(formData);
        return result;
      });

      Promise.all(results).then(async (img_results) => {
        let setImageArray = [];
        img_results.map((image_path, index) => {
          setImageArray.push(image_path);
        });

        const formDataObject = {
          self_image_gallery: setImageArray,
        };
        const result = await AddSelfGalleryImage(
          JSON.stringify(formDataObject)
        );
        if (result.code === 200) {
          dataList();
          onCloseDrawer();
          setIsLoading(false);
          enqueueSnackbar(result.message, { variant: "success" });
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      });
    }
  };

  return (
    <div className="container add-photo new-memories">
      <form onSubmit={handleSubmit}>
        <div className="col-md-12 mt-2 d-flex">
          <div className="row w-100 mb-3">
            <p className="mt-2">Recommended Size (1000x1000)</p>
            {previews &&
              previews.map((file, index) => (
                <div className="col-3 mt-2">
                  <span className="preview">
                    <span onClick={() => handleRemove(index)}>x</span>
                    <img src={file} />
                  </span>
                </div>
              ))}
            <div className="col-3 mt-2">
              <span className="upload-button">
                <input
                  color="primary"
                  accept="image/*"
                  type="file"
                  id="icon-button-file"
                  style={{ display: "none" }}
                  onChange={handleUpload}
                  multiple={true}
                />
                <label htmlFor="icon-button-file">
                  <CloudUploadIcon />
                </label>
              </span>
            </div>
          </div>
        </div>
        <div className="text-end mt-3">
          <button className="submit-button" disabled={isLoading}>
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}
