import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddCategory from "./Categories/AddCategory";
import EditCategory from "./Categories/EditCategory";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import {
  categoriesListing,
  DeleteCategory,
} from "../../DAL/AffirmationCategories/Categories/Categories";
import { s3baseUrl } from "../../config/config";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";
import { htmlDecode } from "../../utils/convertHtml";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function Affirmations(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [categoriesData, setCategoriesData] = useState([]);
  const [categorySlug, setCategorySlug] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editValues, setEditValues] = useState({});
  const { contentSettingData } = useContentSetting();
  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const getCategoriesListing = async () => {
    const result = await categoriesListing();
    if (result.code === 200) {
      setCategoriesData(result.category);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const navigate = useNavigate();
  const handleChange = (id, name) => {
    navigate(`/affirmations/affirmation-list/${id}`, {
      state: name,
    });
  };

  useEffect(() => {
    getCategoriesListing();
  }, []);

  const handleEdit = (value) => {
    setEditValues(value);
    setEditDrawerState(true);
  };
  const handleAgreeDelete = (value) => {
    setCategorySlug(value.category_slug);
    setOpenDelete(true);
  };

  //Deleting Category
  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await DeleteCategory(categorySlug);
    if (result.code === 200) {
      getCategoriesListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {contentSettingData &&
            contentSettingData.affirmation_description ? (
              <div
                className="dashboard_description"
                dangerouslySetInnerHTML={{
                  __html: contentSettingData.affirmation_description,
                }}
              ></div>
            ) : (
              <h2 className="quotes-heading">Affirmations</h2>
            )}
          </div>
          <div className="col-12">
            <button
              className="small-contained-button float-end mt-1 mb-4"
              onClick={handleOpenDrawer}
            >
              Add Category
            </button>
          </div>
        </div>
        <div className="row">
          {categoriesData.length > 0 ? (
            categoriesData.map((value, index) => {
              return (
                <div
                  className="col-lg-4 col-md-6 col-sm-12 mb-3"
                  key={value.category_slug}
                >
                  <div className="card shadow-lg h-100 pods-image pods-cards-shadow">
                    <img
                      src={s3baseUrl + value.image.thumbnail_2}
                      className="card-img-top pods-image cursor"
                      alt="Affirmation"
                      onClick={() => {
                        handleChange(value.category_slug, value.name);
                      }}
                    />
                    <div className="affirmation-dots">
                      <CustomPopover menu={MENU_OPTIONS} data={value} />
                    </div>
                    <CustomConfirmation
                      open={openDelete}
                      setOpen={setOpenDelete}
                      title={"Are you sure you want to delete this Category?"}
                      handleAgree={handleDelete}
                    />
                    <div
                      className="card-body cursor"
                      onClick={() => {
                        handleChange(value.category_slug);
                      }}
                    >
                      <h3 className="card-title h2-heading">
                        {htmlDecode(value.name)}
                      </h3>
                      <div
                        className="programme-card-desc mb-3"
                        dangerouslySetInnerHTML={{
                          __html: htmlDecode(value.description),
                        }}
                      ></div>
                      <div className="card-button">
                        <p className="pods-active-members">
                          <span>{value.activeMembers}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <RecordNotFound title="Categories Not Found" />
          )}
        </div>

        <CustomDrawer
          isOpenDrawer={editDrawerState}
          onOpenDrawer={handleOpenEditDrawer}
          onCloseDrawer={handleCloseEditDrawer}
          pageTitle="Edit Category"
          componentToPassDown={
            <EditCategory
              setIsLoading={setIsLoading}
              dataList={getCategoriesListing}
              editData={editValues}
              onCloseDrawer={handleCloseEditDrawer}
            />
          }
        />

        <CustomDrawer
          isOpenDrawer={drawerState}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          pageTitle="New Category"
          componentToPassDown={
            <AddCategory
              onCloseDrawer={handleCloseDrawer}
              setIsLoading={setIsLoading}
              dataList={getCategoriesListing}
            />
          }
        />
      </div>
    </>
  );
}

export default Affirmations;
