import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, Navigate } from "react-router-dom";
import { get_app_logo } from "src/config/config";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SearchListing } from "src/DAL/Programmes/Programmes";
import { htmlDecode } from "src/utils/convertHtml";
import { RecordNotFound } from "src/components/_dashboard/programmes";

export default function Search() {
  const [select, setSelect] = useState("all");
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isLoadShow, setIsLoadShow] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [totlePages, setTotlePages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [loadMore, setLoadMore] = useState(
    "app/search_for_client?page=0&limit=50"
  );
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleChange = (event) => {
    setSelect(event.target.value);
  };
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const handleSearchCancel = () => {
    setSearchText("");
    setSearchList([]);
    setIsShow(false);
    setIsLoadShow(false);
    localStorage.removeItem("search_text");
    localStorage.removeItem("page_name");
  };
  const handleSearchClick = () => {
    handleSearch();
  };

  const handleSearch = async () => {
    const selected = select ? select : localStorage.getItem("search_type");
    const searched = searchText
      ? searchText
      : localStorage.getItem("search_text");

    setIsLoading(true);
    const result = await SearchListing(
      "app/search_for_client?page=0&limit=50",
      select,
      searchText
    );
    if (result.code === 200) {
      if (result.search_result.length <= 0) {
        setIsShow(true);
      }
      setSearchList(result.search_result);
      setIsLoading(false);
      if (result.total_pages > 0) {
        setIsLoadShow(true);
      } else {
        setIsLoadShow(false);
      }
      setLoadMorePath(result.load_more);
      setTotalCount(result.total_count);
      // localStorage.setItem("search_type", selected);
      localStorage.setItem("search_text", searchText);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleLoadMore = async () => {
    const selected = select ? select : localStorage.getItem("search_type");
    const searched = searchText
      ? searchText
      : localStorage.getItem("search_text");

    const result = await SearchListing(loadMorePath, select, searchText);
    if (result.code === 200) {
      var newArray = searchList.concat(result.search_result);
      setSearchList(newArray);
      setPageNumber(pageNumber + 1);
      setTotlePages(result.total_pages + 1);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
      // localStorage.setItem("search_type", selected);
      // localStorage.setItem("search_text", searched);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMore(false);
    }
  };
  const loadMoreData = () => {
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
    handleLoadMore();
  };
  const handleClose = () => {
    navigate(localStorage.getItem("search_location"));
    localStorage.removeItem("search_text");
    // localStorage.removeItem("search_type");
    localStorage.removeItem("search_location");
    localStorage.removeItem("page_name");

    setIsLoadShow(false);
  };
  const handleNavigate = (value) => {
    if (
      value.type == "contact-us-team" ||
      value.type == "website-faqs" ||
      value.type == "meet-the-team" ||
      value.type == "goal-statement" ||
      value.type == "self-image"
    ) {
      navigate(`/${value.type}`);
    } else {
      if (value.slug && value.slug.length > 0) {
        if (value.type == "calender") {
          navigate(`/${value.type}`);
        } else {
          if (value.type == "market-place") {
            navigate(`/${value.type}/${value.type_id}`, {
              state: {},
            });
          } else {
            navigate(`/${value.type}/${value.slug}`, {
              state: {},
            });
          }
        }
      } else {
        if (value.type_id.length > 0) {
          navigate(`/${value.type}/${value.type_id}`);
        } else {
          navigate(`/${value.type}`);
        }
      }
    }
    localStorage.setItem("page_name", value.type);
  };
  const highlightText = (text, searchText) => {
    if (!searchText) return text;

    const parts = text.split(new RegExp(`(${searchText})`, "gi"));

    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === searchText.toLowerCase() ? (
            <mark key={index} className="highlighted-text">
              {part}
            </mark>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <div className="container">
      <div className="row mt-2">
        <div className="col-5">
          <IconButton className="back-screen-button mt-4" onClick={handleClose}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-2 col-sm-2">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={select}
              fullWidth
              sx={{ width: 160 }}
              label="Select Type"
              onChange={handleChange}>
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"program"}>Programs</MenuItem>
              <MenuItem value={"lesson"}>Program Lessons</MenuItem>
              <MenuItem value={"goal-statement"}>Goal Statement</MenuItem>
              <MenuItem value={"self-image"}>Self Image</MenuItem>
              <MenuItem value={"program_recording"}>Replay Library</MenuItem>
              <MenuItem value={"links"}>Links</MenuItem>
              {/* <MenuItem value={"calendar"}>Calendar</MenuItem> */}
              <MenuItem value={"quote"}>Quotes</MenuItem>
              <MenuItem value={"affirmation"}>Affirmations</MenuItem>
              <MenuItem value={"memories"}>Memories</MenuItem>
              <MenuItem value={"gratitude"}>Gratitude</MenuItem>
              <MenuItem value={"market-place"}>Marketplace</MenuItem>
              <MenuItem value={"support_ticket"}>Support Ticket</MenuItem>
              <MenuItem value={"website-faqs"}>Faqs</MenuItem>
              <MenuItem value={"meet-the-team"}>Meet The Team</MenuItem>
              <MenuItem value={"contact-us-team"}>Contact Us Team</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-10 col-sm-2">
          <TextField
            label="Search"
            value={searchText}
            sx={{ mr: 2, width: "87%" }}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchText && (
                    <ClearIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSearchCancel()}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <button
            variant="contained"
            onClick={handleSearchClick}
            disabled={searchText === ""}
            // className={`small-contained-button ${
            //   searchText === "" ? "disabled-button" : ""
            // }`}
            className="small-contained-button mt-2">
            Search
          </button>
        </div>
      </div>
      {!isLoading ? (
        <>
          <div className="row mt-3">
            {searchList.length > 0 ? (
              searchList.map((value, index) => {
                return (
                  <List
                    key={index}
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                    onClick={() => handleNavigate(value)}>
                    <ListItem
                      alignItems="flex-start"
                      sx={{
                        "&:hover": {
                          backgroundColor: "#ebf8f2",
                          cursor: "pointer",
                        },
                      }}
                      className="list-search">
                      <ListItemText
                        sx={{
                          width: "20px",
                        }}
                        primary={
                          <Typography
                            // variant="h6"
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "#1a93a9",
                              marginBottom: "6px",
                            }}>
                            {/* {value.title} */}
                            {highlightText(value.title, searchText)}
                          </Typography>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{
                                display: "inline",
                                fontSize: "14px",
                              }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                              // dangerouslySetInnerHTML={{
                              //   __html: value?.short_description,
                              // }}
                            ></Typography>
                            {/* {htmlDecode(value.short_description)} */}
                            {highlightText(
                              htmlDecode(value.short_description),
                              searchText
                            )}
                          </React.Fragment>
                        }
                      />
                      <Button className="mt-3 no-hover-effect">
                        {value.type == "vault" ? "Replay Library" : value.type}
                      </Button>
                    </ListItem>

                    <Divider component="li" className="mt-2" />
                  </List>
                );
              })
            ) : (
              <>{isShow && <RecordNotFound title="Search Not Found" />}</>
            )}
          </div>
          {isLoadShow && searchList.length < totalCount ? (
            <div className="col-12 text-center">
              <button
                className="small-contained-button mt-3"
                onClick={loadMoreData}
                disabled={isLoadingMore}>
                {isLoadingMore ? "Loading..." : "Load More"}
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <div className="row mt-4">
          {/* <CircularProgress className={classes.loading} color="primary" /> */}
          <Box sx={{ display: "flex" }}>
            <CircularProgress className="circular-progress" />
          </Box>
        </div>
      )}
    </div>
  );
}
