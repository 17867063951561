import { CircularProgress, Divider } from "@mui/material";
import React from "react";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import { htmlDecode } from "src/utils/convertHtml";
import { useState } from "react";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { teamListing } from "src/DAL/WebsiteTeam/WebsiteTeam";
import { s3baseUrl } from "src/config/config";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MeetTheTeam() {
  const [isLoading, setIsLoading] = useState(true);
  const [teamData, setTeamData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const classes = useStyles();

  const highlightTextInDescription = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parts = text.split(new RegExp(`(${storedSearchText})`, "gi"));
      return parts
        .map((part, index) =>
          part.toLowerCase() === storedSearchText.toLowerCase()
            ? `<mark key=${index} class="highlighted-text">${part}</mark>`
            : part
        )
        .join("");
    } else {
      return text;
    }
  };
  const highlightText = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parts = text.split(new RegExp(`(${storedSearchText})`, "gi"));
      return (
        <span>
          {parts.map((part, index) =>
            part.toLowerCase() === storedSearchText.toLowerCase() ? (
              <mark key={index} className="highlighted-text">
                {part}
              </mark>
            ) : (
              part
            )
          )}
        </span>
      );
    } else {
      return text;
    }
  };
  const getTeamList = async () => {
    const result = await teamListing();
    if (result.code === 200) {
      setTeamData(result.team);
      console.log(result, "resultresultresultresult");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTeamList();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <h2>Hina Khan Team</h2>
      {teamData.length > 0 ? (
        <div className="row">
          {teamData.map((team, index) => (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4 g-4" key={index}>
                <div className="card dashboard-cards-borders h-100">
                  <div className="p-3">
                    <img
                      src={s3baseUrl + team.image.thumbnail_1}
                      alt=""
                      class="img-fluid mx-auto"></img>
                    <h2 className="text-center mt-2">
                      {highlightText(team.name)}
                    </h2>
                    <h3 className="text-center mt-2">
                      {highlightText(team.designation)}
                    </h3>
                  </div>
                  <div className="card-body dashboard-cards">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: highlightTextInDescription(
                          team.short_description
                        ),
                      }}
                      className="programme-card-desc"></p>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      ) : (
        <>
          <RecordNotFound title="Team Not Found" />
        </>
      )}
    </div>
  );
}
