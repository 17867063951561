import { SnackbarProvider, useSnackbar } from "notistack";
import { Button, Slide, StyledEngineProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
// routes
import Routers from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import "bootstrap/dist/css/bootstrap.min.css";
//Dark theme like DD
import "./assets/css/index.css";
import "./assets/css/style.css";
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import { ContextPGIMode } from "./Hooks/PGIModeContext";
import { Pods } from "./pages";
import { get_app_headers, project_name } from "./config/config";
import { Helmet } from "react-helmet";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { get_site_setting } from "./DAL/Payment/Payment";
import { ContentSettingState } from "./Hooks/ContentContext/ContentSettingState";

// ----------------------------------------------------------------------

export default function App() {
  const notistackRef = React.createRef();
  const headers = get_app_headers();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const [stripePublicKey, setStripePublicKey] = useState({});

  const stripePromise = loadStripe(
    "pk_test_51H2sBPIxjPbX33fQYlsCToNuBBTlFI12RzD4IPrr3YuEOYSoYaxQNmpbSNi39Pw28YvMHhz3Tfv7vMXmm3wWUXaf00eC2UhFlk"
  );

  console.log(stripePromise, "stripePromise");

  // const stripePromise = loadStripe(stripePublicKey);

  // get_site_setting
  const getStripePublicKey = () => {
    // if (!localStorage.getItem("token")) {
    //   return loadStripe("");
    // }
    return new Promise(async (resolve, reject) => {
      const result = await get_site_setting();
      if (result.code === 200) {
        // Testing or live keys

        if (result.site_setting.stripe_mode === "sandBox") {
          resolve(loadStripe(result.site_setting.sandBox_publish_key));
        } else {
          resolve(loadStripe(result.site_setting.live_publish_key));
        }
      } else {
        alert(result.message);
      }
    });
  };

  useEffect(() => {
    console.log(stripePublicKey, "stripePublicKey");
  }, [stripePublicKey]);
  useEffect(() => {
    getStripePublicKey();
  }, []);

  return (
    <ContextPGIMode>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <SnackbarProvider
          ref={notistackRef}
          action={(key) => (
            <Button
              className="snackbar-cross-icon"
              onClick={onClickDismiss(key)}>
              <CloseIcon />
            </Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
          maxSnack={3}>
          <Helmet>
            <title>{headers && headers.title && headers.title}</title>
            <meta
              name="description"
              content={headers && headers.description && headers.description}
            />
            <link
              rel="icon"
              type="image/x-icon"
              href={headers && headers.fav_icon && headers.fav_icon}
            />
            {/* <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-NPTTP19FHE"></script>
            <script>
              {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-NPTTP19FHE');`}
            </script> */}
          </Helmet>
          <Elements stripe={getStripePublicKey()}>
            <ContentSettingState>
              <Routers />
            </ContentSettingState>
          </Elements>
        </SnackbarProvider>
      </ThemeConfig>
    </ContextPGIMode>
  );
}
