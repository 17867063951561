import { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

// ----------------------------------------------------------------------
const isAnnouncement = localStorage.getItem("announcement");
const APP_BAR_MOBILE = isAnnouncement == "yes" ? 210 : 64;
const APP_BAR_DESKTOP = 65;
const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { announcementState } = useContentSetting();
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
  useEffect(() => {
    if (location.pathname.includes(localStorage.getItem("page_name"))) {
    } else {
      localStorage.removeItem("page_name");
      localStorage.removeItem("search_text");
    }
  }, [location]);
  return (
    <RootStyle>
      {/* <ContextPGIMode> */}
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle
        style={{ paddingTop: announcementState == "yes" ? "115px" : 65 }}>
        <Outlet />
      </MainStyle>
      {/* </ContextPGIMode> */}
    </RootStyle>
  );
}
