import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { login_by_admin_api } from "../DAL/Login/Login";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";
import { CircularProgress } from "@mui/material";
import {
  ContentSettingApi,
  navbarListAPi,
} from "src/DAL/ContentSetting/ContentSetting";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function LoginByAdmin() {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { handleUserInfo, handleConsultantInfo, START_SOCKET_FORCEFULLY } =
    useContentSetting();
  const {
    handleContentSettingData,
    handleDashboardSettingData,
    handleDashboardTestimonialData,
    handleDashboardEventData,
    handleAdminTimeZone,
    handleNavbarList,
    dashboardSettingData,
  } = useContentSetting();
  const is_seen_video = _get_is_seen_video_localStorage();
  const getContentSetting = async () => {
    const navbar_result = await navbarListAPi();
    if (navbar_result.code === 200) {
      handleNavbarList(navbar_result.menus);
      localStorage.setItem(`navbar_list`, JSON.stringify(navbar_result.menus));
    }
    const result = await ContentSettingApi();
    if (result.code === 200) {
      if (result.content_setting === null) {
        handleDashboardSettingData({});
        handleContentSettingData({});
        handleDashboardTestimonialData([]);
        handleDashboardEventData([]);
        handleAdminTimeZone("");
        return;
      }
      localStorage.setItem(
        `content_setting`,
        JSON.stringify(result.content_setting.client_content_setting)
      );
      localStorage.setItem(
        `client_dashboard_setting`,
        JSON.stringify(result.content_setting.client_dashboard_setting)
      );
      localStorage.setItem(`testmonial`, JSON.stringify(result.testmonial));
      localStorage.setItem(`home_event`, JSON.stringify(result.home_event));
      localStorage.setItem(`admin_time_zone`, JSON.stringify(result.time_zone));
      handleAdminTimeZone(result.time_zone);
      handleDashboardEventData(result.home_event);
      handleDashboardTestimonialData(result.testmonial);
      handleContentSettingData(result.content_setting.client_content_setting);
      handleDashboardSettingData(
        result.content_setting.client_dashboard_setting
      );

      navigate("/dashboard", { replace: true });
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSubmitLogin = async () => {
    setIsLoading(true);
    const data = {
      token: params.token,
      user_type: "client",
    };
    console.log(data, "datadata");
    const result = await login_by_admin_api(data);
    if (result.code === 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.member));
      localStorage.setItem("token", result.token);
      console.log(result, "result.member.consultant");
      handleUserInfo(result.member);
      if (result.member.consultant) {
        localStorage.setItem(
          `consultant_data`,
          JSON.stringify(result.member?.consultant)
        );
        handleConsultantInfo(result.member.consultant);
      } else {
        localStorage.setItem(`consultant_data`, JSON.stringify({}));
      }
      navigate("/dashboard", { replace: true });
      START_SOCKET_FORCEFULLY(result.member._id);

      if (is_seen_video && is_seen_video === "Yes") {
      } else {
        localStorage.setItem("is_seen_video", "No");
      }
      getContentSetting();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate("/login");
    }
  };

  useEffect(() => {
    handleSubmitLogin();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
}
