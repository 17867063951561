import { invokeApi } from "../../bl_libs/invokeApi";

export const teamListing = async () => {
  const requestObj = {
    path: `api/team/team_list_by_type/meet_the_team`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const contactTeamListing = async () => {
  const requestObj = {
    path: `api/team/team_list_by_type/contact_us_team`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
