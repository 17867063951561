import { CircularProgress, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { filter } from "lodash";

import { useNavigate } from "react-router-dom";
import {
  marketPlaceDeleteApi,
  marketPlaceListing,
} from "src/DAL/MarketPlace/MarketPlace";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import CustomConfirmation from "src/components/CustomConfirmation";
import CustomPopover from "src/components/CustomPopover";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function MarketPlace() {
  const navigate = useNavigate();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [clearAll, setClearAll] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [marketList, setMarketList] = useState([]);
  const classes = useStyles();

  const [pageNumber, setPageNumber] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadMore, setLoadMore] = useState("api/post/client?page=0&limit=10");

  const handleRegister = (post) => {
    window.open(post, "_blank");
  };
  const handleEdit = (value) => {
    navigate(`/market-place/edit-post/${value._id}`, {
      state: value,
    });
  };
  const handleMyPosts = (value) => {
    navigate(`/market-place/my-posts`);
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await marketPlaceDeleteApi(deleteDoc._id);
    if (result.code === 200) {
      getMarketPlace(true);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getMarketPlace = async (del) => {
    setIsLoading(true);
    let postData = {
      approved: "all",
      action_by: "all",
      search_text: "",
      status: true,
    };

    try {
      setIsLoading(true);
      const result = await marketPlaceListing(loadMore, postData);
      if (result.code === 200) {
        let newArray = [];
        if (del) {
          newArray = result.post;
        } else {
          newArray = marketList.concat(result.post);
        }

        setMarketList(newArray);

        setPageNumber(pageNumber + 1);
        setTotlePages(result.total_pages);
        setLoadMorePath(result.load_more_url);
        setIsLoading(false);
        setIsLoadingMore(false);
        setClearAll(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
        setClearAll(false);
        setIsLoadingMore(false);
      }
    } catch (error) {}
  };
  const loadMoreData = () => {
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };
  useEffect(() => {
    getMarketPlace();
  }, [loadMore]);
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const MENU_OPTIONS_APPROVED = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <h2>Marketplace</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-sm-12 text-end">
          <button
            className="small-contained-button me-2 mt-1"
            onClick={handleMyPosts}>
            My Posts &nbsp;&nbsp;{" "}
            <Icon width={20} icon="carbon:user-data" className="mb-1" />
          </button>
          <button
            onClick={() => navigate(`/market-place/add-post`)}
            className="small-contained-button">
            Add Post
          </button>
        </div>
      </div>
      <div className="row mt-4">
        {marketList.map((post, index) => {
          return (
            <>
              <div className="col-lg-11 col-md-6 col-sm-12 mb-3">
                <div>
                  <div className="d-flex">
                    <p className="mb-0">
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}>
                        {`Presented By: ${
                          post?.first_name + " " + post?.last_name
                        }`}
                      </Typography>
                    </p>{" "}
                  </div>
                  {post?.company_name && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}>
                      {post?.company_name}
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      color: "#1a93a9",
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}>
                    {post?.title}
                  </Typography>{" "}
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: post?.short_description,
                      }}></div>
                    {post?.post_type == "event" && (
                      <p>
                        <span className="section-text">Time: </span>
                        {post?.time}
                        <span className="section-text ms-3">Date: </span>{" "}
                        {post?.date}
                      </p>
                    )}
                  </div>
                </div>
                <button
                  className="small-contained-button"
                  onClick={() => handleRegister(post?.link)}>
                  {post?.button_text}
                </button>
              </div>
              {post?.is_self && (
                <div className="col-1">
                  {/* <CustomPopoverSection menu={MENU_OPTIONS} />  */}
                  {post.is_approved ? (
                    <>
                      {post?.action_by == "admin" ? (
                        ""
                      ) : (
                        <CustomPopover
                          menu={MENU_OPTIONS_APPROVED}
                          data={post}
                        />
                      )}
                    </>
                  ) : (
                    <CustomPopover menu={MENU_OPTIONS} data={post} />
                  )}
                </div>
              )}

              <hr className="market-divider"></hr>
            </>
          );
        })}
        {totlePages > pageNumber ? (
          <div className="col-12 text-center">
            <button
              className="small-contained-button mt-3"
              onClick={loadMoreData}
              disabled={isLoadingMore}>
              {isLoadingMore ? "Loading..." : "Load More"}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDelete}
      />
    </div>
  );
}
