import { CircularProgress, Divider } from "@mui/material";
import React from "react";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import { useState } from "react";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { contactTeamListing } from "src/DAL/WebsiteTeam/WebsiteTeam";
import { s3baseUrl } from "src/config/config";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ContactUsTeam() {
  const [isLoading, setIsLoading] = useState(true);
  const [teamData, setTeamData] = useState([]);

  const classNamees = useStyles();
  const highlightTextInDescription = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const parts = node.textContent.split(
            new RegExp(`(${storedSearchText})`, "gi")
          );
          const span = document.createElement("span");

          parts.forEach((part, index) => {
            if (index % 2 === 1) {
              const mark = document.createElement("mark");
              mark.className = "highlighted-text";
              mark.textContent = part;
              span.appendChild(mark);
            } else {
              span.appendChild(document.createTextNode(part));
            }
          });

          node.parentNode.replaceChild(span, node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          node.childNodes.forEach(processNode);
        }
      };

      processNode(doc.body);

      return doc.body.innerHTML;
    } else {
      return text;
    }
  };
  const highlightText = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parts = text.split(new RegExp(`(${storedSearchText})`, "gi"));
      return (
        <span>
          {parts.map((part, index) =>
            part.toLowerCase() === storedSearchText.toLowerCase() ? (
              <mark key={index} className="highlighted-text">
                {part}
              </mark>
            ) : (
              part
            )
          )}
        </span>
      );
    } else {
      return text;
    }
  };
  const getTeamList = async () => {
    const result = await contactTeamListing();
    if (result.code === 200) {
      setTeamData(result.team);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTeamList();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classNamees.loading} color="primary" />;
  }
  return (
    <div className="container">
      <h2>Contact Us Team</h2>
      <div className="pt-0">
        <div className="text-center mt-1 mt-lg-5 mb-4">
          <h4 className="contact-us-call">
            Contact us - we're here to support you!
          </h4>
        </div>
        {teamData.map((team, index) => (
          <>
            {index % 2 === 0 ? (
              <div className="row mt-0 team-member">
                <div className="col-md-3 text-center">
                  <img
                    src={s3baseUrl + team.image.thumbnail_1}
                    alt=""
                    className="team-member-image"></img>
                </div>
                <div className="col-md-6">
                  <div>
                    <p>{highlightText(team.designation)}&nbsp;</p>
                    <p>
                      {highlightText(team.name)}
                      <br></br>
                      <p className="mt-2">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: highlightTextInDescription(
                              team.short_description
                            ),
                          }}></span>
                      </p>
                      &nbsp;
                    </p>
                  </div>
                </div>
                <div className="col-md-3 text-center">
                  {/* <img
              src="https://hinakhan-app-prod-bucket.s3.amazonaws.com/upload/3be33b9a-b08f-4f7a-b5b0-f1dc74f3f2b3.png"
              alt=""
              className="img-fluid"></img> */}
                </div>
              </div>
            ) : (
              <div className="row mt-0 team-member">
                <div className="col-md-3 text-center"></div>
                <div className="col-md-6 background-contact">
                  <div>
                    <p>{highlightText(team.designation)}</p>
                    <p>
                      {highlightText(team.name)}
                      <br></br>
                      <p className="mt-2">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: highlightTextInDescription(
                              team.short_description
                            ),
                          }}></span>
                      </p>
                      &nbsp;
                    </p>
                  </div>
                </div>
                <div className="col-md-3 text-center">
                  <img
                    src={s3baseUrl + team.image.thumbnail_1}
                    alt=""
                    className="team-member-image"></img>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
