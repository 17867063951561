import React, { useEffect, useState } from "react";
import PinterestLayout from "src/components/PinterestLayout/Pinterest";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import FormsDrewer from "../../components/FormsDrewer/FormsDrewer";
import AddAffirmation from "./AddAffirmation";
import EditAffirmation from "./EditAffirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AffirmationListApi,
  DeleteAffirmationsApi,
} from "src/DAL/AffirmationCategories/Affirmations/Affirmations";
import { CircularProgress, IconButton } from "@mui/material";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
import { htmlDecode } from "src/utils/convertHtml";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function AffirmationList() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [affirmationData, setAffirmationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [affirmationId, setAffirmationId] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [editValues, setEditValues] = useState({});
  const location = useLocation();

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };
  const handleDetailPage = () => {};

  //Getting lesson detail
  const getAffirmationList = async () => {
    const category_slug = params.category_slug
      ? params.category_slug
      : params.slug;
    const result = await AffirmationListApi(category_slug);
    if (result.code === 200) {
      let affirmationArray = [];
      let affirmationObject = {};
      result.affirmation.map((affirmation, index) => {
        affirmationObject = {
          category_title: affirmation.category.name,
          pinteres_id: affirmation._id,
          pinterestImage: affirmation.bg_image.thumbnail_2,
          pinterestDescription: affirmation.affirmation_text,
          pinterestDate: affirmation.createdAt,
        };
        affirmationArray.push(affirmationObject);
      });
      setAffirmationData(affirmationArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAffirmationList();
  }, []);

  //Getting Note In textarea
  const handleEdit = (value) => {
    setEditValues(value);
    setEditDrawerState(true);
  };
  const handleAgreeDelete = (value) => {
    setAffirmationId(value.pinteres_id);
    setOpenDelete(true);
  };

  //Deleting Affirmation
  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await DeleteAffirmationsApi(affirmationId);
    if (result.code === 200) {
      getAffirmationList();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate("/affirmations")}>
            <ArrowBackIcon />
          </IconButton>
          <button
            className="small-contained-button float-end mt-1"
            onClick={handleOpenDrawer}>
            Add Affirmation
          </button>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12">
          <h1>{htmlDecode(location.state)}</h1>
        </div>
      </div>
      <div className="row media-margin">
        <PinterestLayout
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete this Affirmation?"}
          handleAgree={handleDelete}
          menuOptions={MENU_OPTIONS}
          data={affirmationData}
          notFoundtitle="Affirmations Not Found"
          handleDetailPage={handleDetailPage}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Edit Affirmation"
        componentToPassDown={
          <EditAffirmation
            dataList={getAffirmationList}
            editData={editValues}
            categorySlug={params.category_slug}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="New Affirmation"
        componentToPassDown={
          <AddAffirmation
            onCloseDrawer={handleCloseDrawer}
            dataList={getAffirmationList}
            categorySlug={params.category_slug}
          />
        }
      />
    </div>
  );
}

export default AffirmationList;
