import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import faker from "faker";
import { useSnackbar } from "notistack";
// material
import {
  Box,
  Card,
  Container,
  Link,
  Typography,
  Stack,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlayLessonIcon from "@mui/icons-material/PlayLesson";
import { FaqImage, ContactUs, TeamImage } from "src/assets";
import { htmlDecode } from "src/utils/convertHtml";
import { s3baseUrl } from "src/config/config";
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

export default function DashboardCards({ data }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Card
              className="programm-card h-100"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate(`/meet-the-team`)}>
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                }}>
                <ProgrammesImgStyle
                  alt="lessons"
                  src={s3baseUrl + data?.team_image}
                />
              </Box>
              <Stack
                spacing={2}
                // sx={{ p: 3 }}
                className="lesson-content text-center">
                <h3>{data?.team_text}</h3>
              </Stack>
            </Card>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Card
              className="programm-card h-100"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate(`/website-faqs`)}>
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                }}>
                <ProgrammesImgStyle
                  alt="lessons"
                  src={s3baseUrl + data?.faq_image}
                />
              </Box>

              <Stack
                spacing={2}
                // sx={{ p: 3 }}
                className="lesson-content text-center">
                <h3>{data?.faq_text}</h3>
              </Stack>
            </Card>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Card
              className="programm-card h-100"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate(`/contact-us-team`)}>
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                }}>
                <ProgrammesImgStyle
                  alt="lessons"
                  src={s3baseUrl + data?.contact_us_image}
                />
              </Box>

              <Stack
                spacing={2}
                // sx={{ p: 3 }}
                className="lesson-content text-center">
                <h3>{data?.contact_us_text}</h3>
              </Stack>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
