import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPlayer from "react-player";

const ReactVideoPlayerDashboard = (props) => {
  const [videoDuration, setVideoDuration] = useState();

  // State variable to track the video progress
  const [videoProgress, setVideoProgress] = useState(0);
  const setVideoPlayed = () => {
    if (props && props.setting.video_type !== "repeat") {
      localStorage.setItem("is_seen_video", "Yes");
    }
  };
  const handleVideoDuration = (duration) => {
    setVideoDuration(duration);
  };

  const handleVideoProgress = (progress) => {
    setVideoProgress(Math.ceil(progress.playedSeconds));
  };

  return (
    <div>
      {videoDuration != videoProgress ? (
        !props.seen || props.seen === "No" ? (
          <div className="wrapper">
            <ReactPlayer
              controls
              className="player"
              url={props.url}
              width="100%"
              height="100%"
              stopOnUnmount
              pip
              playsinline
              onPlay={setVideoPlayed}
              onDuration={handleVideoDuration} // Call handleVideoDuration when the video duration is available
              onProgress={handleVideoProgress}
            />
          </div>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: props.setting?.welcome_image,
            }}></div>
        )
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: props.setting?.welcome_image,
          }}></div>
      )}
    </div>
  );
};

export default ReactVideoPlayerDashboard;
