import React from "react";
import { Container, Stack, Typography, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
function GoalStatementList(props) {
  const navigate = useNavigate();
  const { contentSettingData } = useContentSetting();
  const highlightTextInDescription = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const parts = node.textContent.split(
            new RegExp(`(${storedSearchText})`, "gi")
          );
          const span = document.createElement("span");

          parts.forEach((part, index) => {
            if (index % 2 === 1) {
              const mark = document.createElement("mark");
              mark.className = "highlighted-text";
              mark.textContent = part;
              span.appendChild(mark);
            } else {
              span.appendChild(document.createTextNode(part));
            }
          });

          node.parentNode.replaceChild(span, node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          node.childNodes.forEach(processNode);
        }
      };

      processNode(doc.body);

      return doc.body.innerHTML;
    } else {
      return text;
    }
  };
  return (
    <>
      {contentSettingData.goal_statement_description ? (
        <div
          className="dashboard_description"
          dangerouslySetInnerHTML={{
            __html: highlightTextInDescription(
              contentSettingData.goal_statement_description
            ),
          }}></div>
      ) : (
        <h2>Goal Statement</h2>
      )}
      <div className="row">
        <div className="col-12 mt-4">
          <Divider />
        </div>
        <div className="col-12 mt-4 text-end">
          <button
            className="small-contained-button "
            type="button"
            onClick={() => navigate(`/goal-statement/manage-photos`)}>
            Manage Photos
          </button>
        </div>
      </div>
    </>
  );
}

export default GoalStatementList;
