import React, { useEffect, useState } from "react";
import PinterestLayout from "src/components/PinterestLayout/Pinterest";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import FormsDrewer from "../../components/FormsDrewer/FormsDrewer";
import AddAffirmation from "./AddAffirmation";
import EditAffirmation from "./EditAffirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AffirmationListApi,
  AffirmationSearch,
  DeleteAffirmationsApi,
} from "src/DAL/AffirmationCategories/Affirmations/Affirmations";
import { CircularProgress, IconButton } from "@mui/material";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
import { htmlDecode } from "src/utils/convertHtml";
import CustomConfirmation from "src/components/CustomConfirmation";
import { s3baseUrl } from "src/config/config";
import CustomPopover from "src/components/CustomPopover";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AffirmationsSearch() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [affirmationData, setAffirmationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [affirmationId, setAffirmationId] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [editValues, setEditValues] = useState({});
  const location = useLocation();

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };
  const handleDetailPage = () => {};
  const handleSearchAdd = () => {
    navigate(`/affirmations`);
  };

  //Getting lesson detail
  const getAffirmationList = async () => {
    const result = await AffirmationSearch(params.id);
    if (result.code === 200) {
      setAffirmationData(result.affirmation);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAffirmationList();
  }, []);

  //Getting Note In textarea
  const handleEdit = (value) => {
    setEditValues(value);
    setEditDrawerState(true);
  };
  const handleAgreeDelete = (value) => {
    // setAffirmationId(value.pinteres_id);
    if (value.pinteres_id) {
      setAffirmationId(value.pinteres_id);
    } else {
      setAffirmationId(value._id);
    }
    setOpenDelete(true);
  };

  //Deleting Affirmation
  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await DeleteAffirmationsApi(affirmationId);
    if (result.code === 200) {
      navigate(`/affirmations`);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleClose = () => {
    // if (localStorage.getItem("search_location")) {
    //   navigate(`/search`);
    // } else {
    //   navigate(`/affirmations`);
    // }
    navigate(-1);
  };
  const highlightTextInDescription = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const parts = node.textContent.split(
            new RegExp(`(${storedSearchText})`, "gi")
          );
          const span = document.createElement("span");

          parts.forEach((part, index) => {
            if (index % 2 === 1) {
              const mark = document.createElement("mark");
              mark.className = "highlighted-text";
              mark.textContent = part;
              span.appendChild(mark);
            } else {
              span.appendChild(document.createTextNode(part));
            }
          });

          node.parentNode.replaceChild(span, node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          node.childNodes.forEach(processNode);
        }
      };

      processNode(doc.body);

      return doc.body.innerHTML;
    } else {
      return text;
    }
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <IconButton className="back-screen-button" onClick={handleClose}>
              <ArrowBackIcon />
            </IconButton>
            <button
              className="small-contained-button float-end mt-1"
              onClick={handleOpenDrawer}>
              Add Affirmation
            </button>
          </div>
        </div>
        <div className="row section-space">
          <div className="col-12">
            <h1>{affirmationData.category.name}</h1>
          </div>
        </div>
        <div className="row">
          <div id="columns">
            <div
              key={affirmationData._id}
              className="set-display-inline pinterest-card">
              <figure>
                <img
                  className="grid-item"
                  src={s3baseUrl + affirmationData.bg_image.thumbnail_2}
                  alt=""
                  onClick={() =>
                    handleDetailPage(affirmationData?.pinterestSlug)
                  }
                />
                {affirmationData.pinterestDate && (
                  <span>
                    {get_date_with_user_time_zone(
                      affirmationData.createdAt,
                      "DD MMM YYYY",
                      adminTimeZone,
                      userInfo.time_zone
                    )}
                  </span>
                )}
                {MENU_OPTIONS && (
                  <CustomPopover menu={MENU_OPTIONS} data={affirmationData} />
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: highlightTextInDescription(
                      htmlDecode(affirmationData.affirmation_text)
                    ),
                  }}
                  className="programme-card-desc"
                  onClick={() =>
                    handleDetailPage(affirmationData?.pinterestSlug)
                  }></div>
              </figure>
            </div>
          </div>
        </div>

        <CustomDrawer
          isOpenDrawer={editDrawerState}
          onOpenDrawer={handleOpenEditDrawer}
          onCloseDrawer={handleCloseEditDrawer}
          pageTitle="Edit Affirmation"
          componentToPassDown={
            <EditAffirmation
              dataList={getAffirmationList}
              editData={editValues}
              categorySlug={affirmationData.category.category_slug}
              onCloseDrawer={handleCloseEditDrawer}
            />
          }
        />
        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete this Affirmation?"}
          handleAgree={handleDelete}
        />

        <CustomDrawer
          isOpenDrawer={drawerState}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          pageTitle="New Affirmation"
          componentToPassDown={
            <AddAffirmation
              onCloseDrawer={handleCloseDrawer}
              dataList={handleSearchAdd}
              categorySlug={
                params.category_slug
                  ? params.category_slug
                  : affirmationData.category.category_slug
              }
            />
          }
        />
      </div>
    </>
  );
}
