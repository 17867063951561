import { invokeApi } from "../../bl_libs/invokeApi";

export const SelfImageListApi = async (data) => {
  const requestObj = {
    path: `api/member/get_self_image`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const SelfImageSearchApi = async (id) => {
  const requestObj = {
    path: `api/self_image/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const UploadGalleryImageOnS3 = async (data) => {
  const requestObj = {
    path: `api/goal_statement_question/upload_gallery_images/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddSelfGalleryImage = async (data) => {
  const requestObj = {
    path: `api/member/update_self_image_gallery/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteSelfGalleryImage = async (data) => {
  const requestObj = {
    path: `api/goal_statement_question/delete_self_image_from_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddSingleAnswerSelfApi = async (data) => {
  const requestObj = {
    path: `api/member/update_self_image_question`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddAllAnswersSelfImageApi = async (data) => {
  const requestObj = {
    path: `api/member/update_all_self_image_question`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
