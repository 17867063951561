import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import KimResponce from "./KimResponce";
import CustomDrawer from "../FormsDrewer/CustomDrawer";
import { Divider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { goalStatementName } from "../../config/config";
import {
  AddSingleAnswerApi,
  AddAllAnswersApi,
} from "src/DAL/GoalStatement/GoalStatementList";
import { useSnackbar } from "notistack";
import { htmlDecode } from "src/utils/convertHtml";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  AddAllAnswersSelfImageApi,
  AddSingleAnswerSelfApi,
  SelfImageListApi,
} from "src/DAL/SelfImage/SelfImageList";
import SelfImageQuesionsPDF from "../_dashboard/goalStatement/SelfImageQuesionsPDF";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const useStyles = makeStyles((theme) => ({
  dense: {
    marginTop: theme.spacing(0),
  },
}));

const SelfImageMessage = ({
  replies,
  data,
  selfImageCompletedDate,
  selfImageCompletedStatus,
}) => {
  const classes = useStyles();
  const { contentSettingData, userInfo, adminTimeZone } = useContentSetting();
  const [toggle, setToggle] = useState(true);
  const [responceDrawer, setResponceDrawer] = useState(false);
  const [repliesDrawer, setRepliesDrawer] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [questionMessages, setQuestionMessages] = useState([]);
  const [comments, setComments] = useState([]);
  const [question, setQuestion] = useState("");
  const [isAllAnswered, setIsAllAnswered] = useState(false);
  const [inputs, setInputs] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [complete, setComplete] = useState(false);
  const [selfImageDateCompleted, SetSelfImageDateCompleted] = useState("");
  const [selfImageStatus, setSelfImageStatus] = useState(false);

  const getGoalStatementList = async () => {
    const result = await SelfImageListApi();
    if (result.code === 200) {
      console.log(result, "inside");
      SetSelfImageDateCompleted(result.self_image_completed_date);
      setSelfImageStatus(result.self_image_completed_status);
    }
  };

  const toggleInput = () => {
    setToggle(!toggle);
  };
  const handleKimResponce = (question, comments) => {
    setResponceDrawer(true);
    setComments(comments);
    setQuestion(question);
  };
  const handleKimReplies = () => {
    setRepliesDrawer(true);
  };
  const handleChange = (index, event) => {
    let value = event.target.value;
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.answer = value;
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  const startLoading = (index) => {
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.isLoading = true;
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  const hideLoading = (index) => {
    const length = questionMessages.length - 1;
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.isLoading = false;
    temp_element.isExpanded = false;
    temp_state[index] = temp_element;
    if (index !== length) {
      let temp_nex_element = { ...temp_state[index + 1] };
      temp_nex_element.isExpanded = true;
      temp_state[index + 1] = temp_nex_element;
    }
    setQuestionMessages(temp_state);
  };

  const handleOpenDrawer = () => {
    setResponceDrawer(true);
  };

  const handleCloseDrawer = () => {
    setResponceDrawer(false);
  };

  const handleOpenRepliesDrawer = () => {
    setRepliesDrawer(true);
  };

  const handleCloseRepliesDrawer = () => {
    setRepliesDrawer(false);
  };

  const handleSubmitReply = () => {};

  const expandArea = (index) => {
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isExpanded = true;
    } else {
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  const handleSubmit = async (e, index) => {
    e.preventDefault();
    startLoading(index);
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    checkIfAllAnswered(questionMessages);
    const formDataObject = {
      question_id: temp_element._id,
      answer: temp_element.answer,
      self_image_completed_status: isAllAnswered,
    };

    const result = await AddSingleAnswerSelfApi(JSON.stringify(formDataObject));
    if (result.code === 200) {
      hideLoading(index);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      console.log(result, "result123");
      enqueueSnackbar(result.message, { variant: "error" });
      hideLoading(index);
    }
  };
  const checkIfAllAnswered = (data) => {
    console.log(data, "data check if its not empty");

    data.map((value, index) => {
      if (value.answer === "") {
        setIsAllAnswered(false);
        return;
      } else {
        setIsAllAnswered(true);
      }
    });
  };

  const handleSubmitAll = async () => {
    setIsLoadingAll(true);
    let answersArray = [];
    let answersObject = {};
    checkIfAllAnswered(questionMessages);
    questionMessages.map((answers, index) => {
      answersObject = {
        question_id: answers._id,
        answer: answers.answer,
      };
      answersArray.push(answersObject);
    });

    const formDataObject = {
      self_image_completed_status: isAllAnswered,
      self_image_question: answersArray,
    };
    console.log(formDataObject, "formDataObject");
    const result = await AddAllAnswersSelfImageApi(
      JSON.stringify(formDataObject)
    );
    if (result.code == 200) {
      setIsLoadingAll(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setComplete(true);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingAll(false);
    }
  };
  useEffect(() => {
    let questionsArray = [];
    data.map((value, index) => {
      if (index === 0) {
        questionsArray.push({
          ...value,
          isExpanded: true,
          isLoading: false,
        });
      } else {
        questionsArray.push({
          ...value,
          isExpanded: false,
          isLoading: false,
        });
      }
      setQuestionMessages(questionsArray);
    });
    checkIfAllAnswered(data);
  }, [data]);
  const highlightText = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parts = text.split(new RegExp(`(${storedSearchText})`, "gi"));
      return (
        <span>
          {parts.map((part, index) =>
            part.toLowerCase() === storedSearchText.toLowerCase() ? (
              <mark key={index} className="highlighted-text">
                {part}
              </mark>
            ) : (
              part
            )
          )}
        </span>
      );
    } else {
      return text;
    }
  };
  useEffect(() => {
    getGoalStatementList();
  }, [complete]);

  return (
    <>
      <div className="row goal-statement-messages">
        <div className="col-12 my-4">
          <Divider />
        </div>
        <div className="col">
          {questionMessages.length > 0
            ? questionMessages.map((message, index) => {
                return (
                  <div className="card mb-2" key={message.id}>
                    <form
                      onSubmit={(e) => {
                        handleSubmit(e, index);
                      }}>
                      <Accordion expanded={message.isExpanded}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          onClick={() => expandArea(index)}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="svg-color">
                          <Typography>
                            {highlightText(message.question)}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {message.question_type == "textarea" ? (
                            <TextField
                              id="filled-multiline-flexible"
                              fullWidth
                              multiline
                              rows={5}
                              label={htmlDecode(message.question_placeholder)}
                              className={(classes.dense, "textarea-color")}
                              value={htmlDecode(message.answer)}
                              onChange={(e) => handleChange(index, e)}
                              variant="outlined"
                              name="message"
                            />
                          ) : (
                            <TextField
                              id="filled-multiline-flexible"
                              fullWidth
                              label={htmlDecode(message.question_placeholder)}
                              className={(classes.dense, "textarea-color")}
                              value={htmlDecode(message.answer)}
                              onChange={(e) => handleChange(index, e)}
                              variant="outlined"
                              name="message"
                            />
                          )}

                          <div className="d-flex justify-content-between mt-3">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                handleKimResponce(
                                  message.question,
                                  message.comment
                                );
                              }}
                              className="small-contained-button me-2">
                              {`${goalStatementName}'s Response`}
                            </button>
                            <button
                              className="small-contained-button"
                              disabled={message.isLoading}>
                              {message.isLoading ? "Saving..." : "Save & Next"}
                            </button>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-between mt-3">
          <button onClick={handleKimReplies} className="small-contained-button">
            {`${goalStatementName}'s Replies`}
          </button>
          <button
            className="small-contained-button"
            disabled={isLoadingAll}
            onClick={handleSubmitAll}>
            {isLoadingAll ? "Saving..." : "Save All"}
          </button>
        </div>
      </div>
      {/* <div className="row">
        <div className=" col-12 mt-3">
          <div className="download-pdf-button-box text-end">
            <PDFDownloadLink
              document={<SelfImageQuesionsPDF data={questionMessages} />}
              fileName="Self Image Question"
              className="text-start download-pdf-button"
            >
              {({ loading }) => (loading ? "Download PDF" : "Download PDF")}
            </PDFDownloadLink>
          </div>
        </div>
      </div> */}
      {(selfImageCompletedStatus == true || selfImageStatus == true) && (
        <div className="row mt-4">
          <div className="col-md-8 goal-statement-completed-text">
            <p>
              Self Image was completed on the &nbsp;
              {get_date_with_user_time_zone(
                selfImageDateCompleted,
                "DD MMM YYYY",
                userInfo.time_zone,
                adminTimeZone
              )}
            </p>
          </div>
          <div className="col-md-4 download-pdf-button-box">
            <PDFDownloadLink
              document={
                <SelfImageQuesionsPDF
                  data={questionMessages}
                  completed_date={get_date_with_user_time_zone(
                    selfImageCompletedDate,
                    "DD MMM YYYY",
                    userInfo.time_zone,
                    adminTimeZone
                  )}
                />
              }
              fileName="Self Image"
              className="download-pdf-button">
              {({ loading }) => (loading ? "Download" : "Download")}
            </PDFDownloadLink>
          </div>
        </div>
      )}

      <CustomDrawer
        isOpenDrawer={responceDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${goalStatementName}'s Response`}
        componentToPassDown={
          <KimResponce question={question} data={comments} />
        }
      />
      <CustomDrawer
        isOpenDrawer={repliesDrawer}
        onOpenDrawer={handleOpenRepliesDrawer}
        onCloseDrawer={handleCloseRepliesDrawer}
        pageTitle={`${goalStatementName}'s Replies`}
        componentToPassDown={<KimResponce data={replies} />}
      />
    </>
  );
};

export default SelfImageMessage;
