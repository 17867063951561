import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomConfirmation from "src/components/CustomConfirmation";
import {
  marketPlaceDeleteApi,
  marketPlaceDetailApi,
} from "src/DAL/MarketPlace/MarketPlace";
import { useSnackbar } from "notistack";
import CustomPopover from "src/components/CustomPopover";
import { Icon } from "@iconify/react";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function MarketPlaceDetail() {
  const classes = useStyles();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [postData, setPostData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();

  const handleEdit = () => {
    navigate(`/market-place/edit-post/${state?._id}`, {
      state: state,
    });
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleRegister = () => {
    window.open(state?.link ? state?.link : postData?.link, "_blank");
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await marketPlaceDeleteApi(state?._id);
    if (result.code === 200) {
      navigate(-1);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getPostDetail = async () => {
    setIsLoading(true);
    const result = await marketPlaceDetailApi(params.id);
    if (result.code === 200) {
      setPostData(result.post);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const highlightText = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parts = text?.split(new RegExp(`(${storedSearchText})`, "gi"));
      return (
        <span>
          {parts?.map((part, index) =>
            part.toLowerCase() === storedSearchText.toLowerCase() ? (
              <mark key={index} className="highlighted-text">
                {part}
              </mark>
            ) : (
              part
            )
          )}
        </span>
      );
    } else {
      return text;
    }
  };
  const highlightTextInDescription = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const parts = node.textContent.split(
            new RegExp(`(${storedSearchText})`, "gi")
          );
          const span = document.createElement("span");

          parts.forEach((part, index) => {
            if (index % 2 === 1) {
              const mark = document.createElement("mark");
              mark.className = "highlighted-text";
              mark.textContent = part;
              span.appendChild(mark);
            } else {
              span.appendChild(document.createTextNode(part));
            }
          });

          node.parentNode.replaceChild(span, node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          node.childNodes.forEach(processNode);
        }
      };

      processNode(doc.body);

      return doc.body.innerHTML;
    } else {
      return text;
    }
  };
  useEffect(() => {
    getPostDetail();
  }, []);
  console.log(postData, "postData");
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const MENU_OPTIONS_APPROVED = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Post Detail</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-11 col-md-6 col-sm-12 mt-3">
          <div>
            <p className="mb-0">
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}>
                {`Presented By: ${
                  state?.name
                    ? state?.name
                    : postData?.first_name + " " + postData?.last_name
                }`}
              </Typography>
            </p>{" "}
            {(state?.company_name || postData?.company_name) && (
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                }}>
                {state?.company_name
                  ? state?.company_name
                  : postData?.company_name}
              </Typography>
            )}
            <Typography
              sx={{
                color: "#1a93a9",
                fontSize: "25px",
                fontWeight: "bold",
                marginBottom: "7px",
              }}>
              {state?.title
                ? highlightText(state?.title)
                : highlightText(postData?.title)}
            </Typography>{" "}
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: state?.short_description
                    ? highlightTextInDescription(state?.short_description)
                    : highlightTextInDescription(postData?.short_description),
                }}></div>
              {(state?.post_type == "event" ||
                postData?.post_type == "event") && (
                <p className="mb-0">
                  <span className="section-text">Time: </span>
                  {state?.time ? `${state?.time}` : `${postData?.time}`}(
                  {state?.timezone})
                  <span className="section-text ms-3">Date: </span>{" "}
                  {state?.date ? state?.date : postData?.date}
                </p>
              )}
              {(state?.expiry_date || postData?.expiry_date) && (
                <span className="d-flex">
                  <p style={{ marginRight: "20px" }}>
                    <span className="section-text">Expiry: </span>
                    {state.expiry_date
                      ? moment(state.expiry_date).format("YYYY-MM-DD")
                      : moment(postData.expiry_date).format("YYYY-MM-DD")}
                    {/* 20-03-2024 */}
                  </p>
                  <Tooltip title="This post will be removed after the specified expiry date">
                    <Icon
                      width={20}
                      icon="bi:info-lg"
                      className="mb-1 info-icon"
                    />
                  </Tooltip>
                </span>
              )}
            </div>
          </div>
          <button
            className="small-contained-button mb-3"
            onClick={handleRegister}>
            {state?.button_text ? state?.button_text : postData?.button_text}
          </button>
          {state?.is_approved === "rejected" && (
            <div className="d-flex">
              <h3>Rejected Reason: </h3>
              <p className="ms-2">{state?.reject_reason}</p>
            </div>
          )}
        </div>
        <div className="col-1">
          {state?.is_approved === "approved" ||
          postData?.is_approved === "approved" ? (
            <>
              <CustomPopover menu={MENU_OPTIONS_APPROVED} />
            </>
          ) : (
            <>
              <CustomPopover menu={MENU_OPTIONS} />
            </>
          )}
        </div>
        <hr className="market-divider"></hr>
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDelete}
      />
    </div>
  );
}
