import "./css/thankspage.css";
import WebHeader from "./WebHeader";
import WebFooter from "./WebFooter";
import { websitePagesContent } from "src/DAL/WebPages/WebPages";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CopyToClipboard from "react-copy-to-clipboard";
import { useParams } from "react-router-dom";
import { projectBaseUrl } from "src/config/config";
import GeneralFooter from "./GeneralFooter";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function ThanksPage() {
  const { enqueueSnackbar } = useSnackbar();
  const [pageData, setPageData] = useState({});
  const [paymentPlan, setPaymentPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const params = useParams();

  const getWebsitePagesContent = async () => {
    const result = await websitePagesContent(
      JSON.stringify({ page_slug: params.page_slug })
    );

    if (result.code === 200) {
      setPageData(result.Sale_page);
      setPaymentPlan(result.payment_plan[0]);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getWebsitePagesContent();
  }, []);

  const setCopiedPassword = () => {
    enqueueSnackbar("Link Copied To Clipboard", { variant: "success" });
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="landing-page">
      <WebHeader data={pageData} />
      <section className="thanks-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-11 col-lg-7">
              <div className="back-color">
                <div
                  dangerouslySetInnerHTML={{
                    __html: htmlDecode(pageData.thankyou_page_content),
                  }}
                ></div>

                <div className="mt-3">
                  <CopyToClipboard
                    text={`${projectBaseUrl}${params.page_slug}/${params.affiliate_name}`}
                    onCopy={() => setCopiedPassword(true)}
                  >
                    <Tooltip title="Click to copy Link">
                      <div className="learn-btn">
                        <a
                          href={`${projectBaseUrl}${params.page_slug}/${params.affiliate_name}`}
                          rel="noopener noreferrer"
                        >
                          <button>{`${projectBaseUrl}${params.page_slug}/${params.affiliate_name}`}</button>
                        </a>
                      </div>
                    </Tooltip>
                  </CopyToClipboard>
                </div>
                {/* <ul>
                  {pageData.sale_page_detail.facebook_link && (
                    <li>
                      <a
                        href={pageData.sale_page_detail.facebook_link}
                        target="_blank"
                      >
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </a>
                    </li>
                  )}
                  {pageData.sale_page_detail.instagram_link && (
                    <li>
                      <a
                        href={pageData.sale_page_detail.instagram_link}
                        target="_blank"
                      >
                        <i className="fa fa-instagram" aria-hidden="true" />
                      </a>
                    </li>
                  )}
                  {pageData.sale_page_detail.youtube_link && (
                    <li>
                      <a
                        href={pageData.sale_page_detail.youtube_link}
                        target="_blank"
                      >
                        <i className="fa fa-youtube" aria-hidden="true" />
                      </a>
                    </li>
                  )}
                  {pageData.sale_page_detail.pinterest_link && (
                    <li>
                      <a
                        href={pageData.sale_page_detail.pinterest_link}
                        target="_blank"
                      >
                        <i className="fa fa-pinterest" />
                      </a>
                    </li>
                  )}
                  {pageData.sale_page_detail.tiktok_link && (
                    <li>
                      <a
                        href={pageData.sale_page_detail.tiktok_link}
                        target="_blank"
                        className="me-0"
                      >
                        <i className="fab fa-tiktok" />
                      </a>
                    </li>
                  )}
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <GeneralFooter data={pageData} />
    </div>
  );
}
