// material
// components

import DashboardCircle from "src/components/_dashboard/app/DashboardCircle";
import { ReactVideoPlayerDashboard } from "src/components";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { project_name } from "src/config/config";
import DashboardCard from "src/components/_dashboard/app/DashboardCard";
import DashboardCards from "src/components/_dashboard/app/DashboardCards";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const {
    contentSettingData,
    dashboardSettingData,
    dashboardTestimonialData,
    dashboardEventData,
  } = useContentSetting();
  const is_seen_video = _get_is_seen_video_localStorage();
  console.log(dashboardSettingData, "dashboardSettingData");

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 dashboard_description">
          {contentSettingData && contentSettingData.dashboard_description ? (
            <div
              dangerouslySetInnerHTML={{
                __html: contentSettingData.dashboard_description,
              }}></div>
          ) : (
            <h2 className="dashboard-heading">Hina's Hub</h2>
          )}
        </div>
      </div>
      <div className="row">
        {(project_name == "DD" || project_name == "BASIC_DEV") && (
          <>
            <div className="col-12 mt-5 mx-auto text-center">
              <DashboardCard />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <DashboardCircle data={dashboardSettingData} />
            </div>
          </>
        )}

        <div className="col-sm-12 col-md-12 col-lg-12 my-3">
          <ReactVideoPlayerDashboard
            url={dashboardSettingData?.video_url}
            setting={dashboardSettingData}
            seen={is_seen_video}
          />
        </div>
        <DashboardCards data={dashboardSettingData} />
      </div>
    </div>
    // </Page>
  );
}
