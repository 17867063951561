import React, { useEffect, useState } from "react";
import Message from "src/components/message/Message";
import { GoalStatementList } from "../../components/_dashboard/goalStatement";
import GoalGallerySlider from "./GoalGallerySlider";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { GoalStatementListApi } from "src/DAL/GoalStatement/GoalStatementList";
import { s3baseUrl } from "src/config/config";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function GoalStatement(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [goalStatementQuestions, setGoalStatementQuestions] = useState([]);
  const [goalStatementGallery, setGoalStatementGallery] = useState([]);
  const [replies, setreplies] = useState([]);
  const [goalStatementCompletedStatus, setGoalStatementCompletedStatus] =
    useState(false);
  const [goalStatementCompletedDate, setGoalStatementCompletedDate] = useState(
    new Date()
  );

  const getGoalStatementList = async () => {
    const result = await GoalStatementListApi();
    if (result.code === 200) {
      setGoalStatementQuestions(result.goal_statement_question);
      setGoalStatementCompletedStatus(result.goal_statement_completed_status);
      setGoalStatementCompletedDate(result.goal_statement_completed_date);
      let replyArray = [];
      let replyObject = {};
      result.reply.map((reply, index) => {
        replyObject = {
          comment: reply.message,
          createdAt: reply.createdAt,
          image: reply.image,
          audio_file: reply.audio_file,
        };
        replyArray.push(replyObject);
      });

      setreplies(replyArray);
      let galleryArray = [];
      let galleryObject = {};
      result.goal_statement_gallery.map((gallery, index) => {
        galleryObject = {
          original: s3baseUrl + gallery.thumbnail_1,
          thumbnail: s3baseUrl + gallery.thumbnail_2,
        };
        galleryArray.push(galleryObject);
      });
      setGoalStatementGallery(galleryArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGoalStatementList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <GoalStatementList />
        </div>
      </div>
      <br></br>
      {goalStatementGallery.length > 0 && (
        <div className="row mb-md-5">
          <GoalGallerySlider data={goalStatementGallery} />
        </div>
      )}
      {goalStatementQuestions.length > 0 && (
        <div className="row mt-md-5">
          <div className="col-12">
            <Message
              replies={replies}
              data={goalStatementQuestions}
              goalStatementCompletedStatus={goalStatementCompletedStatus}
              goalStatementCompletedDate={goalStatementCompletedDate}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default GoalStatement;
