import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { faqsListing } from "src/DAL/WebsiteFaqs/WebsiteFaqs";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import { htmlDecode } from "src/utils/convertHtml";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function WebsiteFaqs() {
  const [isLoading, setIsLoading] = useState(true);
  const [faqsData, setFaqsData] = useState([]);

  const classes = useStyles();
  const highlightTextInDescription = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const parts = node.textContent.split(
            new RegExp(`(${storedSearchText})`, "gi")
          );
          const span = document.createElement("span");

          parts.forEach((part, index) => {
            if (index % 2 === 1) {
              const mark = document.createElement("mark");
              mark.className = "highlighted-text";
              mark.textContent = part;
              span.appendChild(mark);
            } else {
              span.appendChild(document.createTextNode(part));
            }
          });

          node.parentNode.replaceChild(span, node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          node.childNodes.forEach(processNode);
        }
      };

      processNode(doc.body);

      return doc.body.innerHTML;
    } else {
      return text;
    }
  };
  const highlightText = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parts = text.split(new RegExp(`(${storedSearchText})`, "gi"));
      return (
        <span>
          {parts.map((part, index) =>
            part.toLowerCase() === storedSearchText.toLowerCase() ? (
              <mark key={index} className="highlighted-text">
                {part}
              </mark>
            ) : (
              part
            )
          )}
        </span>
      );
    } else {
      return text;
    }
  };
  const getFaqsList = async () => {
    const result = await faqsListing();
    if (result.code === 200) {
      setFaqsData(result.faq);
      console.log(result, "resultresultresultresult");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getFaqsList();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <h2>Frequently Asked Questions</h2>
      {faqsData.length > 0 ? (
        <>
          <div className="row">
            <div className="col-12">
              {faqsData.map((faq, index) => (
                <Box key={index} className="team-member">
                  <Box sx={{ my: 3, mx: 2 }}>
                    <Grid container alignItems="center">
                      <Grid>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          className="text-color-cards mb-3">
                          {highlightText(faq.question_statment)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: highlightTextInDescription(
                            faq.answer_statment
                          ),
                        }}></div>
                    </div>
                  </Box>
                  <Divider className="divider-mui" variant="middle" />
                </Box>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <RecordNotFound title="Faqs Not Found" />
        </>
      )}
    </div>
  );
}
