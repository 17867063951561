import React, { useState, useEffect } from "react";
import PinterestLayout from "src/components/PinterestLayout/Pinterest";
import {
  gratitudeSearch,
  gratitudesListing,
} from "../../DAL/Gratitude/Gratitude";
import { useSnackbar } from "notistack";
import EditGratitude from "./EditGratitude";
import AddGratitude from "./AddGratitude";
import { CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DeleteGratitudeApi } from "../../DAL/Gratitude/Gratitude";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "../../utils/convertHtml";
import { get_date_with_user_time_zone } from "src/utils/constants";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function GratitudeSearch() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [gratitudesData, setGratitudesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [gratitudeId, setGratitudeId] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [editValues, setEditValues] = useState({});
  const { contentSettingData, userInfo, adminTimeZone } = useContentSetting();
  const params = useParams();
  const navigate = useNavigate();

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };
  const handleDetailPage = () => {};
  const handleSearchAdd = () => {
    navigate(`/gratitude`);
  };

  const getGratitudesListing = async () => {
    console.log(params, "paramsssssssssss");
    const result = await gratitudeSearch(params.id);
    if (result.code === 200) {
      let gratitudeArray = [];
      let gratitudeObject = {};
      gratitudeObject = {
        pinteres_id: result.gratitude._id,
        pinterestImage: result.gratitude.gratitude_gallery.thumbnail_1,
        pinterestDescription: result.gratitude.gratitude_text,
        pinterestDate: result.gratitude.gratitude_date,
      };

      setGratitudesData(result.gratitude);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  //Getting Note In textarea
  const handleEdit = (value) => {
    setEditValues(value);
    setEditDrawerState(true);
  };
  const handleAgreeDelete = (value) => {
    console.log(value, "valueeeeeeeeeeeeeeeeeee");
    if (value.pinteres_id) {
      setGratitudeId(value.pinteres_id);
    } else {
      setGratitudeId(value._id);
    }

    setOpenDelete(true);
  };

  //Deleting Note
  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await DeleteGratitudeApi(gratitudeId);
    if (result.code === 200) {
      navigate(`/gratitude`);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleClose = () => {
    navigate(-1);
  };
  useEffect(() => {
    getGratitudesListing();
  }, []);
  const highlightTextInDescription = (text) => {
    const storedSearchText = localStorage.getItem("search_text");
    if (storedSearchText) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const parts = node.textContent.split(
            new RegExp(`(${storedSearchText})`, "gi")
          );
          const span = document.createElement("span");

          parts.forEach((part, index) => {
            if (index % 2 === 1) {
              const mark = document.createElement("mark");
              mark.className = "highlighted-text";
              mark.textContent = part;
              span.appendChild(mark);
            } else {
              span.appendChild(document.createTextNode(part));
            }
          });

          node.parentNode.replaceChild(span, node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          node.childNodes.forEach(processNode);
        }
      };

      processNode(doc.body);

      return doc.body.innerHTML;
    } else {
      return text;
    }
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div className="col-12">
            {contentSettingData && contentSettingData.gratitude_description ? (
              <div
                className="dashboard_description"
                dangerouslySetInnerHTML={{
                  __html: contentSettingData.gratitude_description,
                }}></div>
            ) : (
              <h2 className="quotes-heading">Gratitude</h2>
            )}
          </div>
          <div className="col-12">
            <IconButton className="back-screen-button" onClick={handleClose}>
              <ArrowBackIcon />
            </IconButton>
            <button
              className="small-contained-button float-end mt-1 mb-4"
              onClick={handleOpenDrawer}>
              Add Gratitude
            </button>
          </div>
        </div>

        <div className="row">
          <div id="columns">
            <div
              key={gratitudesData?.id}
              className="set-display-inline pinterest-card">
              <figure>
                <img
                  className="grid-item"
                  src={
                    s3baseUrl + gratitudesData?.gratitude_gallery?.thumbnail_1
                  }
                  alt=""
                  onClick={() =>
                    handleDetailPage(gratitudesData?.pinterestSlug)
                  }
                />
                {gratitudesData?.gratitude_date && (
                  <span>
                    {get_date_with_user_time_zone(
                      gratitudesData?.gratitude_date,
                      "DD MMM YYYY",
                      adminTimeZone,
                      userInfo.time_zone
                    )}
                  </span>
                )}
                {MENU_OPTIONS && (
                  <CustomPopover menu={MENU_OPTIONS} data={gratitudesData} />
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: highlightTextInDescription(
                      htmlDecode(gratitudesData?.gratitude_text)
                    ),
                  }}
                  className="programme-card-desc"
                  onClick={() =>
                    handleDetailPage(gratitudesData?.pinterestSlug)
                  }></div>
              </figure>
            </div>
          </div>
        </div>

        <CustomDrawer
          isOpenDrawer={editDrawerState}
          onOpenDrawer={handleOpenEditDrawer}
          onCloseDrawer={handleCloseEditDrawer}
          pageTitle="Edit Gratitude"
          componentToPassDown={
            <EditGratitude
              dataList={getGratitudesListing}
              editData={editValues}
              onCloseDrawer={handleCloseEditDrawer}
            />
          }
        />

        <CustomDrawer
          isOpenDrawer={drawerState}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          pageTitle="New Gratitude"
          componentToPassDown={
            <AddGratitude
              dataList={handleSearchAdd}
              onCloseDrawer={handleCloseDrawer}
            />
          }
        />
        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete this Gratitude?"}
          handleAgree={handleDelete}
        />
      </div>
    </>
  );
}
