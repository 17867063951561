import { invokeApi } from "../../../bl_libs/invokeApi";

export const AffirmationListApi = async (category_slug) => {
  const requestObj = {
    path: `api/affirmation/affirmation_list_by_category/${category_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AffirmationSearch = async (affirmation_id) => {
  const requestObj = {
    path: `api/affirmation/${affirmation_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const AddAffirmationsApi = async (data) => {
  const requestObj = {
    path: `api/affirmation/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteAffirmationsApi = async (affirmation_id) => {
  const requestObj = {
    path: `api/affirmation/${affirmation_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const EditAffirmationsApi = async (affirmation_id, data) => {
  const requestObj = {
    path: `api/affirmation/${affirmation_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
