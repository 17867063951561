import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSnackbar } from "notistack";
import { marketPlaceAddApi } from "src/DAL/MarketPlace/MarketPlace";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import Autocomplete from "@mui/material/Autocomplete";
import { TimeZones } from "src/utils/constants";

export default function AddMarketPlace() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [productDetailDescriptionCk, setProductDetailDescriptionCk] =
    useState("");
  const [eventDetailDescriptionCk, setEventDetailDescriptionCk] = useState("");
  const [promotionDetailDescriptionCk, setPromoDetailDescriptionCk] =
    useState("");
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    title: "",
    feature_type: "",
    event_title: "",
    status: true,
    promotion_title: "",
    registration_link: "",
    promotion_button_text: "",
    product_button_text: "",
    event_button_text: "",
    product_link: "",
    promotion_link: "",
    event_description: "",
    product_description: "",
    promo_description: "",
    event_time: "00:00",
    event_date: new Date(),
    expiry_date: null,
    timezone: "",
  });
  const [timeZoneValue, setTimeZoneValue] = useState();
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeDate = (event) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        event_date: event,
      };
    });
  };
  const handleChangeExpiryDate = (event) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        expiry_date: event,
      };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let formattedExpiryDate = "";
    if (inputs.expiry_date) {
      const ExpiryDate = new Date(inputs.expiry_date);
      const yearExpiry = ExpiryDate.getFullYear();
      const monthExpiry = String(ExpiryDate.getMonth() + 1).padStart(2, "0");
      const dayExpiry = String(ExpiryDate.getDate()).padStart(2, "0");
      formattedExpiryDate = `${yearExpiry}-${monthExpiry}-${dayExpiry}`;
    }
    const startDate = new Date(inputs.event_date);
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    const postData = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      expiry_date: formattedExpiryDate,
      company_name: inputs.company_name,
      title: inputs.title,
      post_type: inputs.feature_type,
      status: inputs.status,
    };

    if (inputs.feature_type == "event") {
      // postData.title = inputs.event_title;
      postData.link = inputs.registration_link;
      postData.button_text = inputs.event_button_text;
      postData.time = inputs.event_time;
      postData.date = formattedDate;
      postData.short_description = eventDetailDescriptionCk;
      postData.timezone = timeZoneValue;
    }
    // if (inputs.feature_type == "promotional") {
    //   postData.title = inputs.promotion_title;
    //   postData.link = inputs.promotion_link;
    //   postData.short_description = promotionDetailDescriptionCk;
    //   postData.button_text = inputs.promotion_button_text;
    // }
    if (inputs.feature_type == "product") {
      postData.button_text = inputs.product_button_text;
      postData.link = inputs.product_link;
      postData.short_description = productDetailDescriptionCk;
    }

    console.log(postData, "postDatapostData");

    const result = await marketPlaceAddApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  console.log(inputs, "helooooo");
  useEffect(() => {
    setTimeZoneValue(inputs.timezone);
  }, [inputs.timezone]);
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Add Post</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            fullWidth
            required
            name="first_name"
            value={inputs.first_name}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            fullWidth
            required
            name="last_name"
            value={inputs.last_name}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            fullWidth
            required
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Company Name"
            variant="outlined"
            fullWidth
            name="company_name"
            value={inputs.company_name}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY-MM-DD"
              inputFormat="YYYY-MM-DD"
              label="When does this coupon or event expire? (if applicable)"
              name="expiry_date"
              value={inputs.expiry_date}
              onChange={(e) => handleChangeExpiryDate(e)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // required={true}
                  fullWidth
                  helperText="This post will be removed after the specified expiry date"
                />
              )}
            />
          </LocalizationProvider>
        </div>
        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status*</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Status*"
              onChange={handleChange}>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div> */}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Feature Type *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="feature_type"
              value={inputs.feature_type}
              label="Feature Type *"
              onChange={handleChange}>
              <MenuItem value="event">Events Feature Section</MenuItem>
              {/* <MenuItem value="promotional">
                Specials and Promotional Offers
              </MenuItem> */}
              <MenuItem value="product">Products / Services</MenuItem>
            </Select>
          </FormControl>
        </div>
        {inputs.feature_type == "event" && (
          <>
            <p className="mt-4 section-text">Events Feature Section</p>
            {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                fullWidth
                required
                name="event_title"
                value={inputs.event_title}
                onChange={handleChange}
              />
            </div> */}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
              <TextField
                id="outlined-basic"
                label="Button Text (5 words max)"
                variant="outlined"
                fullWidth
                required
                name="event_button_text"
                value={inputs.event_button_text}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
              <TextField
                id="outlined-basic"
                label="Button Link"
                variant="outlined"
                fullWidth
                required
                name="registration_link"
                value={inputs.registration_link}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="YYYY-MM-DD"
                  inputFormat="YYYY-MM-DD"
                  label="Event Date"
                  name="event_date"
                  value={inputs.event_date}
                  onChange={(e) => handleChangeDate(e)}
                  renderInput={(params) => (
                    <TextField {...params} required={true} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                variant="outlined"
                id="time"
                fullWidth
                label="Event Time"
                type="time"
                name="event_time"
                required={true}
                value={inputs.event_time}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <div>
                <Autocomplete
                  value={timeZoneValue}
                  onChange={(event, newValue) => {
                    setTimeZoneValue(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={TimeZones}
                  renderInput={(params) => (
                    <TextField {...params} required label="Time Zone" />
                  )}
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <h4>Description *</h4>
              <TinyEditor
                setDetailDescription={setEventDetailDescriptionCk}
                detailDescriptionCk={eventDetailDescriptionCk}
              />
            </div>
          </>
        )}
        {/* {inputs.feature_type == "promotional" && (
          <>
            <p className="mt-4 section-text">Specials and Promotional Offers</p>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                fullWidth
                required
                name="promotion_title"
                value={inputs.promotion_title}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
              <TextField
                id="outlined-basic"
                label="Button Text"
                variant="outlined"
                fullWidth
                required
                name="promotion_button_text"
                value={inputs.promotion_button_text}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Button Link"
                variant="outlined"
                fullWidth
                required
                name="promotion_link"
                value={inputs.promotion_link}
                onChange={handleChange}
              />
            </div>
           
            <div className="col-12 mt-5">
              <h4>Description *</h4>
              <TinyEditor
                setDetailDescription={setPromoDetailDescriptionCk}
                detailDescriptionCk={promotionDetailDescriptionCk}
              />
            </div>
          </>
        )} */}
        {inputs.feature_type == "product" && (
          <>
            <p className="mt-4 section-text">Products / Services</p>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
              <TextField
                id="outlined-basic"
                label="Button Text (5 words max)"
                variant="outlined"
                fullWidth
                required
                name="product_button_text"
                value={inputs.product_button_text}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
              <TextField
                id="outlined-basic"
                label="Button Link"
                variant="outlined"
                fullWidth
                required
                name="product_link"
                value={inputs.product_link}
                onChange={handleChange}
              />
            </div>
            {/* <div className="col-12 mt-4">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Description of Signature Product"
                  multiline
                  rows={3}
                  name="product_description"
                  value={inputs.product_description}
                  onChange={handleChange}
                />
                <FormHelperText>(1-2 sentences)</FormHelperText>
              </FormControl>
            </div> */}

            <div className="col-12 mt-3">
              <h4>Description *</h4>
              <TinyEditor
                setDetailDescription={setProductDetailDescriptionCk}
                detailDescriptionCk={productDetailDescriptionCk}
              />
            </div>
          </>
        )}
        <div className="text-end mt-4">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
